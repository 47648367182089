import { Flex, Heading, Spinner } from '@chakra-ui/react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { ApiContext } from '../../../contexts/Api'

import ContainerBox from '../../../components/Container'
import AdditionalInformation from './AdditionalInformation'
import ConsumerOfTheSketch from './Consumer'
import SketchDate from './Date'
import ISSRetention from './ISSRetention'
import ItemsOfTheSketch from './Items'
import KeysOfTheSketch from './Keys'
import OperationOfTheSketch from './Operation'
import SketchOptions from './Options'

interface SketcheItem {
  id: string
  code: string
  description: string
  ncm: string
  serviceCode: string
  unityValue: number
  unity: string
  amount: string | number
  value: string | number
  order: string
  orderItemNumber: string
}

interface Sketch {
  id: string
  information: {
    additionalInformation: string
    date: string
    retencaoISS: boolean
    operation: {
      _id: string
      goal?: number
    }
    referenceKey: string[]
  }
  addressee?: {
    name: string
    document: string
  }
  items: SketcheItem[]
  typeNf: string
}

interface SketchContextProperties {
  sketch?: Sketch
  setSketch: (sketch: Sketch) => void
}

export const SketchContext = createContext<SketchContextProperties>({
  setSketch: () => null
})

const EditSketch: React.FC<RouteComponentProps<{ id?: string }>> = (props) => {
  const sketchId = props.match.params.id

  const apiContext = useContext(ApiContext)

  const [sketch, setSketch] = useState<Sketch>()

  useEffect(() => {
    apiContext.get(`/sketches/${sketchId}`).then((response) => {
      setSketch(response.data)
    })
  }, [apiContext, sketchId])

  if (sketch === undefined) {
    return (
      <ContainerBox>
        <Flex height={100} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      </ContainerBox>
    )
  }

  return (
    <SketchContext.Provider value={{ sketch, setSketch }}>
      <ContainerBox>
        <Flex justifyContent="space-between">
          <Heading fontWeight="300">Rascunho de {sketch.typeNf}</Heading>
          <SketchDate
            date={sketch.information.date}
            sketchId={sketchId as string}
            updateDate={(date) =>
              setSketch({
                ...sketch,
                information: { ...sketch.information, date }
              })
            }
          />
        </Flex>

        <OperationOfTheSketch
          sketchId={sketchId as string}
          operationId={sketch.information.operation._id}
          typeNf={sketch.typeNf}
          updateOperation={(_id) =>
            setSketch({
              ...sketch,
              information: {
                ...sketch.information,
                operation: { ...sketch.information.operation, _id }
              }
            })
          }
        />

        <KeysOfTheSketch
          sketchId={sketchId as string}
          operationGoal={sketch.information.operation.goal}
          keys={sketch.information.referenceKey}
          updateKeys={(referenceKey) => {
            setSketch({
              ...sketch,
              information: {
                ...sketch.information,
                referenceKey
              }
            })
          }}
        />

        <ISSRetention
          ISSRetention={sketch.information.retencaoISS}
          sketchId={sketchId as string}
          typeNf={sketch.typeNf}
          updateISSRetention={(retencaoISS) => {
            setSketch({
              ...sketch,
              information: { ...sketch.information, retencaoISS }
            })
          }}
        />

        <ConsumerOfTheSketch
          sketchId={sketchId as string}
          consumer={sketch.addressee}
          updateConsumer={(addressee) => {
            setSketch({
              ...sketch,
              addressee
            })
          }}
        />

        <ItemsOfTheSketch
          sketchId={sketchId as string}
          items={sketch.items}
          typeNf={sketch.typeNf}
        />

        <AdditionalInformation />

        <SketchOptions />
      </ContainerBox>
    </SketchContext.Provider>
  )
}

export default EditSketch

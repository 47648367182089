import React, { useState, useEffect } from 'react'
import { Box, Heading, Flex, Collapse, useDisclosure } from '@chakra-ui/react'

import InputBlock from '../../../../../../components/InputBlock'
import { Search2Icon } from '@chakra-ui/icons'
import FindList from './List'
import { ItemData } from '..'

interface ItemFinderProperties {
  sketchType: string
  onSelection: (data: ItemData) => void
}

const ItemFinder: React.FC<ItemFinderProperties> = ({
  sketchType,
  onSelection
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [first, setFirst] = useState(true)
  const [filter, setFilter] = useState('')
  const [focusedInput, setFocusedInput] = useState(false)
  const [cursorInSearch, setCursorInSearch] = useState(false)

  function handlePointerEnter() {
    if (first) {
      setFirst(false)
      setFocusedInput(false)
      setCursorInSearch(true)
    }
    if (!cursorInSearch) {
      setCursorInSearch(true)
    }
  }

  useEffect(() => {
    if (cursorInSearch) {
      onOpen()
    }
    if (!focusedInput && !cursorInSearch) {
      onClose()
    }
  }, [focusedInput, cursorInSearch, onOpen, onClose])

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      padding={3}
      onPointerMove={handlePointerEnter}
      onPointerEnter={() => setCursorInSearch(true)}
      onPointerLeave={() => setCursorInSearch(false)}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h3" size="md" fontWeight="300">
          Buscar
        </Heading>
        <Search2Icon />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box padding={1}>
          <InputBlock
            autoFocus={false}
            uppercase
            marginTop
            name="descriptionFilter"
            label="Descrição"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
            onFocus={() => setFocusedInput(true)}
            onBlur={() => setFocusedInput(false)}
          />
          {filter.length > 2 && (
            <FindList
              filter={filter}
              sketchType={sketchType}
              setSelectedItem={(data: ItemData) => {
                setFilter('')
                onSelection(data)
              }}
            />
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

export default ItemFinder

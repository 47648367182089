import React, { useState, useContext, useRef } from 'react'
import { Box, useToast, Spinner, Flex } from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'

import InputBlock from '../../components/InputBlock'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import { ArrowBackIcon } from '@chakra-ui/icons'

interface CancelFormProperties {
  nfeId: string
  handleSuccess: () => void
  handleCancel: () => void
}

const CancelForm: React.FC<CancelFormProperties> = ({
  nfeId,
  handleSuccess,
  handleCancel
}) => {
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  const errorTramitionToastRef = useRef<string | number | undefined>()

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()

    setLoading(true)
    const response = await apiContext.put(`/invoices/${nfeId}/cancel`, { reason })
    if (response.status === 400) {
      errorTramitionToastRef.current = toast({
        title: response.data.message,
        description: response.data.motivo,
        status: 'error',
        isClosable: true,
        duration: 30000
      })
      setLoading(false)
      return
    }

    toast({
      title: response.data.message,
      status: 'info',
      isClosable: true,
      duration: 2000
    })
    setLoading(false)

    handleSuccess()
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
    >
      {loading ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <InputBlock
            isRequired
            uppercase
            name="reason"
            value={reason}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setReason(event.target.value)
            }}
            label={`Motivo - ${reason.length}`}
          />
          <Flex marginTop={4} justifyContent="space-between">
            <IconButton
              aria-label="voltar"
              icon={<ArrowBackIcon />}
              onClick={handleCancel}
            />
            <Button colorScheme="red" type="submit">
              Cancelar NFe
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

export default CancelForm

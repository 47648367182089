import React, { useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'

import { TokenContext } from './Token'

if (!process.env.REACT_APP_API_URL) {
  throw new Error('Devina a variável REACT_APP_API_URL no arquivo .env.local')
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => status <= 401
})

export const ApiContext = React.createContext(api)

const ApiContextProvider: React.FC = ({ children }) => {
  const toast = useToast()
  const tokenContext = useContext(TokenContext)

  api.defaults.headers.Authorization = `Bearer ${tokenContext.token}`

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message.includes('code 403')) {
        if (tokenContext.token !== null) {
          toast({
            id: 'expired session',
            title: 'Sessão expirada',
            duration: 2000,
            isClosable: true,
            status: 'warning'
          })
          tokenContext.clearToken()
        }
      }
      throw error
    }
  )

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export default ApiContextProvider

import validator from 'is-my-date-valid'
const validate = validator({ format: 'DD/MM/YYYY' })

export function formatDate(jsonDate: string) {
  const valid = validate(jsonDate)
  if (valid) {
    return jsonDate
  }
  const arrayDate = jsonDate
    .split('T')[0]
    .split('-')
    .map((part) => Number(part))

  const arrayHour = jsonDate
    .split('T')[1]
    .split('.')[0]
    .split(':')
    .map((part) => Number(part))

  const date = new Date(
    arrayDate[0],
    arrayDate[1] - 1,
    arrayDate[2],
    arrayHour[0],
    arrayHour[1],
    arrayHour[2]
  )
  date.setHours(date.getHours() - 3)

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  return `${day}/${month}/${date.getFullYear()}`
}

export function validateDate(date: string) {
  const valid = validate(date)
  if (valid) {
    const arrayDate = date.split('/')
    return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`
  } else {
    return false
  }
}

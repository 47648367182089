import React from 'react'
import { Heading, ListItem } from '@chakra-ui/react'

import history from '../../services/history'

import ContainerBox from '../../components/Container'
import List from '../../components/List'

const Configurations: React.FC = () => {
  return (
    <ContainerBox>
      <Heading fontWeight="300">Configurações</Heading>
      <List>
        <ListItem
          padding={3}
          cursor="pointer"
          _hover={{ backgroundColor: 'gray.100' }}
          display="flex"
          justifyContent="center"
          textTransform="uppercase"
          onClick={() => history.push('/configuracoes/alterar-senha')}
        >
          Alterar senha
        </ListItem>
        <ListItem
          padding={3}
          cursor="pointer"
          _hover={{ backgroundColor: 'gray.100' }}
          display="flex"
          justifyContent="center"
          textTransform="uppercase"
          onClick={() => history.push('/configuracoes/atualizar-cadastro')}
        >
          Atualizar cadastro
        </ListItem>
      </List>
    </ContainerBox>
  )
}

export default Configurations

import React, { useState, useContext } from 'react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Heading,
  Flex,
  useToast,
  ListItem,
  Spinner
} from '@chakra-ui/react'

import { ApiContext } from '../../../contexts/Api'

import IconButton from '../../../components/IconButton'
import InputMask from '../../../components/InputMask'
import List from '../../../components/List'

interface KeysOfTheSketchProperties {
  operationGoal?: number
  sketchId: string
  keys: string[]
  updateKeys: (keys: string[]) => void
}

const KeysOfTheSketch: React.FC<KeysOfTheSketchProperties> = (properties) => {
  const toast = useToast()
  const apiContext = useContext(ApiContext)

  const [key, setKey] = useState('')
  const [inputLoading, setInputLoading] = useState(false)
  const [keysLoading, setKeysLoading] = useState(false)

  async function addKey(event: React.FormEvent<HTMLDivElement>) {
    event.preventDefault()
    setInputLoading(true)

    if (key.length !== 54) {
      toast({
        title: 'A chave deve ter 44 números',
        duration: 2000,
        isClosable: true,
        status: 'error'
      })
      return
    }

    if (properties.keys.includes(key)) {
      toast({
        title: 'A chave informada já foi adicionada',
        duration: 2000,
        isClosable: true,
        status: 'error'
      })
      return
    }

    const response = await apiContext.put(
      `/sketches/${properties.sketchId}/reference-key`,
      { key }
    )

    properties.updateKeys([...properties.keys, key])
    setKey('')
    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    setInputLoading(false)
  }

  async function deleteKey(key: string) {
    setKeysLoading(true)
    const response = await apiContext.delete(
      `/sketches/${properties.sketchId}/reference-key/${key}`
    )

    const newKeys = properties.keys.filter((sketchKey) => sketchKey !== key)
    properties.updateKeys(newKeys)

    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    setKeysLoading(false)
  }

  if (!properties.operationGoal || properties.operationGoal !== 4) {
    return null
  }

  return (
    <Box marginTop={8}>
      <Heading size="lg" fontWeight="300">
        Chaves de referencia
      </Heading>

      {inputLoading ? (
        <Flex
          marginTop={4}
          justifyContent="center"
          alignItems="center"
          height="40px"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex marginTop={4} as="form" onSubmit={addKey}>
          <InputMask
            buttonRight
            value={key}
            name="referenceKey"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setKey(event.target.value)
            }
          />
          <IconButton
            type="submit"
            aria-label="adicionar"
            icon={<AddIcon />}
            borderRadius="0 8px 8px 0"
          />
        </Flex>
      )}

      {properties.keys.length > 0 && (
        <List>
          {properties.keys.map((key) => (
            <ListItem
              key={key}
              padding={2}
              _hover={{ backgroundColor: 'gray.100' }}
              display="flex"
              justifyContent="space-between"
            >
              {key}
              <IconButton
                isDisabled={keysLoading}
                margin={-2}
                aria-label="excluir chave"
                variant="link"
                colorScheme="red"
                icon={<DeleteIcon />}
                onClick={() => deleteKey(key)}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )
}

export default KeysOfTheSketch

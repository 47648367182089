import React, { useContext, useState } from 'react'
import { Heading, Flex, Box, useToast } from '@chakra-ui/react'

import { ApiContext } from '../../../contexts/Api'
import history from '../../../services/history'

import ContainerBox from '../../../components/Container'
import InputBlock from '../../../components/InputBlock'
import Button from '../../../components/Button'
import { CompanyContext } from '../../../contexts/Company'

const CreateProduct: React.FC = () => {
  const apiContext = useContext(ApiContext)
  const companyContext = useContext(CompanyContext)
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    code: '',
    description: '',
    ncm: '',
    unity: '',
    value: ''
  })

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)

    const response = await apiContext.post('/items', {
      ...form,
      type: 'product'
    })
    if (response.status === 400) {
      toast({
        title: response.data.message,
        duration: 2000,
        isClosable: true,
        status: 'error'
      })
      setLoading(false)
      return
    }
    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    history.push(`/itens/${response.data.id}`)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    let { name, value } = event.target

    if (name === 'description' && value.length > 120) {
      value = value.substr(0, 120)
    }
    if (name === 'unity' && value.length > 6) {
      value = value.substr(0, 6)
    }

    setForm({ ...form, [name]: value })
  }

  return (
    <ContainerBox>
      <Heading fontWeight="300">Novo produto</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        {companyContext.configurations.itemsWithCodes && (
          <InputBlock
            uppercase
            name="code"
            label="Código"
            value={form.code}
            onChange={handleInputChange}
          />
        )}
        <InputBlock
          isRequired
          uppercase
          marginTop={companyContext.configurations.itemsWithCodes}
          name="description"
          label={`Descrição ${form.description.length}/120`}
          value={form.description}
          onChange={handleInputChange}
        />
        <InputBlock
          isRequired
          marginTop
          name="ncm"
          label="NCM"
          value={form.ncm}
          onChange={handleInputChange}
          mask={[/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/]}
          maskChar=""
        />
        <InputBlock
          marginTop
          isRequired
          uppercase
          label="Unidade"
          name="unity"
          value={form.unity}
          onChange={handleInputChange}
        />
        <InputBlock
          money
          marginTop
          isRequired
          label="Valor unitário"
          name="value"
          value={form.value}
          onChange={handleInputChange}
        />
        <Flex marginTop={5} justifyContent="flex-end">
          <Button isLoading={loading} type="submit">
            Salvar
          </Button>
        </Flex>
      </Box>
    </ContainerBox>
  )
}

export default CreateProduct

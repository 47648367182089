import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Heading,
  Flex,
  Text,
  Box,
  ListItem,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from '@chakra-ui/react'
import { HiPrinter, HiDocumentDownload, HiCog, HiLink } from 'react-icons/hi'

import { ApiContext } from '../../contexts/Api'
import { moneyMask, documentMask } from '../../helpers/mask'

import ContainerBox from '../../components/Container'
import List from '../../components/List'
import Button from '../../components/Button'
import CancelForm from './CancelForm'
import CorrectionLetter from './CorrectionLetter'
import { EmailIcon } from '@chakra-ui/icons'

interface Item {
  description: string
  value: number
  value_unity: number
}

interface Addressee {
  name: string
  document: string
  street: string
  number: string
  complement: string
  district: string
  city: string
  state: string
}

interface Invoice {
  addressee: Addressee
  items: Item[]
  pdf: string
  pdfcce: string
  gdf: string
  canceled: boolean
  additionalInformation: string
  response: string
  value: number
  operation: string
  date: string
  xml: ''
  protocolTime: number
}

const ShowInvoice: React.FC<RouteComponentProps<{ id?: string }>> = (
  properties
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [openCorrectionLetterForm, setOpenCorrectionLetterForm] = useState(
    false
  )
  const [openCancelForm, setOpenCancelForm] = useState(false)
  const [invoice, setInvoice] = useState<Invoice>({
    pdf: '',
    pdfcce: '',
    gdf: '',
    addressee: {
      name: '',
      document: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: ''
    },
    items: [],
    canceled: false,
    additionalInformation: '',
    response: '',
    value: 0,
    operation: '',
    date: '',
    xml: '',
    protocolTime: 0
  })

  const apiContext = useContext(ApiContext)

  const printPdf = (document?: string) => {
    let pdf = invoice.pdf
    let title = 'DANFe'
    if (document === 'cce') {
      pdf = invoice.pdfcce
      title = 'DACCe'
    }

    const printWindow = window.open('', '_blank')
    if (printWindow === null) {
      throw new Error('Este erro nunca será chamado')
    }
    printWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
            body {
              margin: 0px !important;
            }
          </style>
        </head>
        <body>
          <embed width=100% height=100% type="application/pdf"
            src="data:application/pdf;base64,${pdf}"></embed>
        </body>
      </html>
    `)
    printWindow.document.close()
  }

  useEffect(() => {
    apiContext
      .get(`/invoices/${properties.match.params.id}`)
      .then((response) => {
        const dateArray = response.data.date.split('T')
        response.data.date = dateArray[0].split('-').reverse().join('/')

        setInvoice(response.data)
      })
  }, [apiContext, properties.match.params.id])

  const {
    name,
    document,
    street,
    number,
    complement,
    district,
    city,
    state
  } = invoice.addressee

  return (
    <ContainerBox>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalBody display="flex" flexDirection="column" paddingBottom={4}>
            {openCancelForm && (
              <CancelForm
                nfeId={properties.match.params.id as string}
                handleCancel={() => setOpenCancelForm(false)}
                handleSuccess={() => {
                  setInvoice({ ...invoice, canceled: true })
                  onClose()
                }}
              />
            )}
            {openCorrectionLetterForm && (
              <CorrectionLetter
                nfeId={properties.match.params.id as string}
                handleCancel={() => setOpenCorrectionLetterForm(false)}
                handleSuccess={() => {
                  apiContext
                    .get(`/invoices/${properties.match.params.id}`)
                    .then((response) => {
                      const dateArray = response.data.date.split('T')
                      response.data.date = dateArray[0]
                        .split('-')
                        .reverse()
                        .join('/')

                      setInvoice(response.data)
                    })
                  onClose()
                }}
              />
            )}
            {!openCancelForm && !openCorrectionLetterForm && (
              <>
                <Link
                  content="XML"
                  href={`data:application/xml;base64, ${invoice.xml}`}
                  download={`XML ${properties.match.params.id}.xml`}
                  textDecoration="none"
                  _focus={{ outline: 'none' }}
                  _hover={{ textDecoration: 'none' }}
                >
                  <Button width="full">Baixar XML</Button>
                </Link>
                {!invoice.canceled && (
                  <>
                    <Button
                      marginTop={4}
                      onClick={() => setOpenCorrectionLetterForm(true)}
                    >
                      Enviar carta de correção
                    </Button>
                      <Button
                        colorScheme="red"
                        marginTop={4}
                        onClick={() => setOpenCancelForm(true)}
                      >
                        Cancelar NFe
                      </Button>
                  </>
                )}
              </>
            )}
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Heading fontWeight="300" size="lg">
          Nota Fiscal {properties.match.params.id}
        </Heading>
        <Text fontSize="4xl" lineHeight="none">
          {moneyMask(invoice.value)}
        </Text>
      </Flex>
      <Text>{invoice.operation}</Text>
      <Text>{invoice.date}</Text>

      <Flex marginTop={5} flexDirection="column" alignItems="center">
        <Text
          fontWeight={invoice.canceled ? '500' : '700'}
          fontSize={invoice.canceled ? 'md' : 'xl'}
          color={
            invoice.response.includes('Autorizado o uso da NF') && !invoice.canceled
              ? 'green.500'
              : 'black'
          }
          textDecoration={invoice.canceled ? 'line-through' : 'none'}
        >
          {invoice.response}
        </Text>
        {invoice.canceled && (
          <Text fontWeight="700" fontSize="xl" color="yellow.500">
            NF-e CANCELADA
          </Text>
        )}
      </Flex>

      <Box marginY={5}>
        <Heading size="md">{name}</Heading>
        <Text>CNPJ/CPF: {documentMask(document)}</Text>
        <Text>
          {street} {number} {complement}
        </Text>
        <Text>
          {district}, {city} - {state}
        </Text>
      </Box>

      <List>
        {invoice.items.map((item) => (
          <ListItem
            key={`${item.description}-${item.value_unity}`}
            padding={3}
            display="flex"
            justifyContent="space-between"
          >
            {item.description}
            <Text>{moneyMask(item.value)}</Text>
          </ListItem>
        ))}
      </List>

      {invoice.additionalInformation && (
        <Box marginTop={5}>
          <Heading size="md">Informações complementares</Heading>
          <Text>{invoice.additionalInformation}</Text>
        </Box>
      )}

      <Flex justifyContent="space-between" marginTop={5}>
        <Button onClick={onOpen}>
          <HiCog size={20} />
        </Button>
        <Flex>
          {invoice.pdfcce && (
            <Button marginRight={5} onClick={() => printPdf('cce')}>
              <EmailIcon />
            </Button>
          )}
          {
            invoice.gdf && (
              <Link
                target="_blank"
                href={invoice.gdf}
              >
                <Button>
                  <HiLink size={20} />
                </Button>
              </Link>
            )
          }
          <Button marginLeft={5} onClick={() => printPdf()}>
            <HiPrinter size={20} />
          </Button>
          <Link
            marginLeft={5}
            href={`data:application/pdf;base64, ${invoice.pdf}`}
            download={`DANFE ${properties.match.params.id}.pdf`}
          >
            <Button>
              <HiDocumentDownload size={20} />
            </Button>
          </Link>
        </Flex>
      </Flex>
    </ContainerBox>
  )
}

export default ShowInvoice

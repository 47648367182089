import React, { useState, useContext } from 'react'
import { useDisclosure, Flex, useToast } from '@chakra-ui/react'

import Button from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import NewItemForm from './Form'
import ItemFinder from './Finder'
import { ApiContext } from '../../../../../contexts/Api'
import { SketchContext } from '../..'

interface NewSketchItemProperties {
  sketchType: string
  sketchId: string
}

export interface ItemData {
  code: string
  description: string
  ncm: string
  serviceCode: string
  unity: string
  amount: string | number
  value: string | number
  order: string
  orderItemNumber: string
}

const NewSketchItem: React.FC<NewSketchItemProperties> = ({
  sketchType,
  sketchId
}) => {
  const sketchContext = useContext(SketchContext)
  const apiContext = useContext(ApiContext)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [form, setForm] = useState<ItemData>({
    code: '',
    description: '',
    ncm: '',
    serviceCode: '',
    unity: 'UN',
    amount: '1',
    value: '',
    order: '',
    orderItemNumber: ''
  })

  async function handleSubmit(item: ItemData) {
    item.amount = parseFloat(item.amount as string)
    if (String(item.value).indexOf('R$ ') !== -1) {
      item.value = parseFloat(
        (item.value as string)
          .replace('R$ ', '')
          .replace('.', '')
          .replace(',', '.')
      )
    }

    const response = await apiContext.post(`/sketches/${sketchId}/items`, {
      item: { ...item, sketchType }
    })

    if (response.status === 400) {
      toast({
        title: response.data.message,
        status: 'error',
        isClosable: true,
        duration: 2000
      })
      return
    }

    toast({
      title: response.data.message,
      status: 'success',
      isClosable: true,
      duration: 2000
    })
    if (sketchContext.sketch) {
      sketchContext.setSketch({
        ...sketchContext.sketch,
        items: [
          ...sketchContext.sketch.items,
          {
            id: response.data.id,
            ...item,
            unityValue: item.value as number,
            description: item.description.toUpperCase(),
            value: parseFloat(((item.value as number) * item.amount).toFixed(2))
          }
        ]
      })
    }
    setForm({
      code: '',
      description: '',
      ncm: '',
      serviceCode: '',
      unity: 'UN',
      amount: '1',
      value: '',
      order: '',
      orderItemNumber: ''
    })
    onClose()
  }

  function onSelection(data: ItemData) {
    setForm({ ...form, ...data })
  }

  return (
    <Flex>
      <Modal
        header="Adicionar novo item"
        isOpen={isOpen}
        onClose={() => {
          setForm({
            code: '',
            description: '',
            ncm: '',
            serviceCode: '',
            unity: 'UN',
            amount: '1',
            value: '',
            order: '',
            orderItemNumber: ''
          })
          onClose()
        }}
        size="xl"
      >
        <ItemFinder sketchType={sketchType} onSelection={onSelection} />
        <NewItemForm
          sketchType={sketchType}
          formData={form}
          setFormData={setForm}
          handleSubmit={handleSubmit}
        />
      </Modal>
      <Button marginLeft={5} size="sm" onClick={onOpen}>
        Adicionar item
      </Button>
    </Flex>
  )
}

export default NewSketchItem

export function moneyMask(int: number) {
  let value = String(int)
  if (value === '0') {
    value = '0.00'
  }

  value = `${value}`
  if (!value.match(/\./)) {
    value = `${value}.00`
  }

  value = value.replace('.', ',')
  value = value.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2')

  const v = value.split(',')
  if (v[1].length < 2) {
    value = `${value}0`
  }

  const qtdLoop = (value.length - 3) / 3
  let count = 0

  while (qtdLoop > count) {
    count++
    value = value.replace(/(\d+)(\d{3}.*)/, '$1.$2')
    value = value.replace(/^(0)(\d)/g, '$2')
  }
  return `R$ ${value}`
}

export function cnpjMask(value: string, oldValue: string) {
  value = value.replace(/\D/gim, '')
  if (value.length > 14) {
    return oldValue
  }
  if (value.length === 14) {
    value = value.replace(
      /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
      '$1.$2.$3/$4-$5'
    )
  }
  return value
}

export function documentMask(value = '', oldValue?: string) {
  value = value.replace(/\D/gim, '')
  if (value.length === 11) {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  if (value.length === 14) {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    )
  }
  if (oldValue && value.length > 14) {
    return oldValue
  }
  return value
}

export function onlyNumbers(value: string) {
  return value.replace(/\D/gim, '')
}

export function serviceCodeMask(value: string, oldValue: string) {
  value = value.replace(/\D/gim, '')
  if (value.length > 4) {
    return oldValue
  }
  if (value.length === 4) {
    value = value.replace(/(\d{2})?(\d{2})/, '$1.$2')
  }
  return value
}

export function cepMask(value: string) {
  value = value.replace(/\D/gim, '')
  if (value.length === 8) {
    value = value.replace(/(\d{2})?(\d{3})?(\d{3})/, '$1.$2-$3')
  }
  return value
}

export function ncmMask(value: string) {
  value = value.replace(/\D/gim, '')
  if (value.length === 8) {
    value = value.replace(/(\d{4})?(\d{2})?(\d{2})/, '$1.$2.$3')
  }
  return value
}

import React from 'react'
import { Flex, Spinner, ListItem, Text } from '@chakra-ui/react'

import history from '../../services/history'

import List from '../../components/List'

interface Item {
  id: string
  description: string
  value: number
}

interface ItemsListProperties {
  items?: Item[]
}

const ItemsList: React.FC<ItemsListProperties> = ({ items }) => {
  if (items === undefined) {
    return (
      <Flex height={100} justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (items.length === 0) {
    return <Text marginTop={5}>Nada cadastro por aqui...</Text>
  }

  return (
    <List>
      {items.map((product) => (
        <ListItem
          key={product.id}
          padding={3}
          cursor="pointer"
          _hover={{ backgroundColor: 'gray.100' }}
          onClick={() => history.push(`/itens/${product.id}`)}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {product.description}
        </ListItem>
      ))}
    </List>
  )
}

export default ItemsList

import React from 'react'
import {
  IconButton as ChakraIconButton,
  IconButtonProps
} from '@chakra-ui/react'

const IconButton: React.FC<IconButtonProps> = (properties) => {
  if (
    properties.colorScheme !== undefined ||
    properties.variant !== undefined
  ) {
    return <ChakraIconButton _focus={{ outline: 'none' }} {...properties} />
  }

  return (
    <ChakraIconButton
      color="white"
      backgroundColor="gray.500"
      transition="background 0.3s, color 0.3s"
      fontWeight="300"
      _focus={{ outline: 'none' }}
      _hover={{ backgroundColor: 'blue.500', color: 'black' }}
      {...properties}
    />
  )
}

export default IconButton

import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { Heading, Flex, Spinner, Text } from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'

import ContainerBox from '../../components/Container'
import { documentMask, cepMask } from '../../helpers/mask'
import Button from '../../components/Button'

interface Consumer {
  _id: string
  name: string
  document: string
  stateRegistration: string
  municipalRegistration: string
  contribuition: '1' | '2' | '9'
  address: {
    street: string
    number: string
    complement: string
    district: string
    city: { name: string }
    state: string
    zipCode: string
  }
}

const ConsumerShow: React.FC<RouteComponentProps<{ id?: string }>> = (
  properties
) => {
  const apiContext = useContext(ApiContext)

  const [consumer, setConsumer] = useState<Consumer>()

  useEffect(() => {
    apiContext
      .get<Consumer>(`/addressees/${properties.match.params.id}`)
      .then((response) => {
        setConsumer(response.data)
      })
  }, [apiContext, properties.match.params.id])

  return (
    <ContainerBox>
      {!consumer ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Heading size="lg" textAlign="center">
            {consumer.name}
          </Heading>
          <Text textAlign="center" marginBottom={3}>
            {documentMask(consumer.document)}
          </Text>

          {consumer.stateRegistration && (
            <Text>Inscrição estadual: {consumer.stateRegistration}</Text>
          )}
          {consumer.municipalRegistration && (
            <Text>Inscrição municipal: {consumer.municipalRegistration}</Text>
          )}

          <Text marginTop={3}>
            {consumer.address.street} {consumer.address.number}{' '}
            {consumer.address.complement}
          </Text>
          <Text>
            {consumer.address.district} {consumer.address.city.name} -{' '}
            {consumer.address.state}
          </Text>
          <Text>CEP: {cepMask(consumer.address.zipCode)}</Text>
        </>
      )}
      <Flex justifyContent="flex-end">
        <Link to={`/clientes/${properties.match.params.id}/editar`}>
          <Button>Editar</Button>
        </Link>
      </Flex>
    </ContainerBox>
  )
}

export default ConsumerShow

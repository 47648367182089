import React, { useEffect, useContext, useState } from 'react'
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ListItem,
  Flex,
  Spinner,
  Text,
  useToast,
  Input,
  Heading
} from '@chakra-ui/react'

import { ApiContext } from '../../../contexts/Api'

import List from '../../../components/List'
import { documentMask } from '../../../helpers/mask'

interface Consumer {
  name: string
  document: string
}

interface ConsumerOfTheSketchProperties {
  sketchId: string
  consumer?: Consumer
  updateConsumer: (consumer: Consumer) => void
}

const ConsumerOfTheSketch: React.FC<ConsumerOfTheSketchProperties> = (
  properties
) => {
  const apiContext = useContext(ApiContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [finder, setFinder] = useState('')
  const [consumers, setConsumers] = useState<Consumer[]>()

  useEffect(() => {
    apiContext.get('/addressees/autocomplete').then((response) => {
      const data = []
      for (const d in response.data) {
        const parts = d.split(' => ')
        data.push({ name: parts[0], document: parts[1] })
      }
      setConsumers(data)
    })
  }, [apiContext])

  async function handleSelect(consumer: Consumer) {
    setLoading(true)
    const response = await apiContext.put(
      `/sketches/${properties.sketchId}/addressee`,
      { document: consumer.document }
    )

    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })

    properties.updateConsumer(consumer)
    setLoading(false)
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecione o cliente</ModalHeader>
          <ModalBody paddingBottom={4}>
            <Input
              value={finder}
              placeholder="Buscar por nome ou CNPJ/CPF"
              textTransform="uppercase"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFinder(event.target.value)
              }
            />
            {!consumers || loading ? (
              <Flex marginTop={20} justifyContent="center" alignItems="center">
                <Spinner size="xl" />
              </Flex>
            ) : (
              <List>
                {consumers
                  .filter(
                    (consumer) =>
                      consumer.name.includes(finder.toUpperCase()) ||
                      consumer.document.includes(finder)
                  )
                  .map((consumer) => (
                    <ListItem
                      key={consumer.document}
                      display="flex"
                      justifyContent="space-between"
                      padding={3}
                      cursor="pointer"
                      _hover={{ backgroundColor: 'gray.100' }}
                      onClick={() => handleSelect(consumer)}
                    >
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        style={{ textOverflow: 'ellipsis' }}
                      >
                        {consumer.name}
                      </Text>
                      <Text as="span" whiteSpace="nowrap" marginLeft={4}>
                        {consumer.document}
                      </Text>
                    </ListItem>
                  ))}
              </List>
            )}
          </ModalBody>
          <ModalCloseButton _focus={{ outline: 'none' }} />
        </ModalContent>
      </Modal>

      <Heading marginTop={8} size="lg" fontWeight="300">
        Cliente
      </Heading>

      {properties.consumer ? (
        <Flex
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          paddingY={2}
          paddingX={4}
          cursor="pointer"
          justifyContent="center"
          onClick={onOpen}
        >
          <Text
            as="span"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {properties.consumer.name}
          </Text>
          <Text as="span" marginX={2}>
            -
          </Text>
          <Text as="span" whiteSpace="nowrap">
            {documentMask(properties.consumer.document)}
          </Text>
        </Flex>
      ) : (
        <Button
          textAlign="center"
          textTransform="uppercase"
          display="block"
          width="full"
          variant="outline"
          fontWeight="500"
          _focus={{ outline: 'none' }}
          onClick={onOpen}
        >
          Selecionar Cliente
        </Button>
      )}
    </>
  )
}

export default ConsumerOfTheSketch

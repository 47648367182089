import React, { useContext, useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DeleteIcon } from '@chakra-ui/icons'
import {
  Flex,
  Button as ChakraButton,
  IconButton,
  Heading,
  Tooltip,
  Select,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useToast,
  ModalBody,
  Box,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

import history from '../../services/history'

import ContainerBox from '../../components/Container'
import { ApiContext } from '../../contexts/Api'
import { documentMask, cepMask } from '../../helpers/mask'
import Button from '../../components/Button'
import Axios from 'axios'
import InputBlock from '../../components/InputBlock'

interface Consumer {
  _id: string
  name: string
  document: string
  stateRegistration: string
  municipalRegistration: string
  contribuition: '1' | '2' | '9' | string
  address: {
    street: string
    number: string
    complement: string
    district: string
    city: { name: string }
    state: string
    zipCode: string
  }
}

const EditConsumer: React.FC<RouteComponentProps<{ id?: string }>> = (
  properties
) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const apiContext = useContext(ApiContext)

  const [consumer, setConsumer] = useState({
    _id: '',
    document: '',
    name: '',
    stateRegistration: '',
    municipalRegistration: '',
    contribuition: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    zipCode: ''
  })

  useEffect(() => {
    apiContext
      .get<Consumer>(`/addressees/${properties.match.params.id}`)
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = response.data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const address: any = response.data.address
        address.zipCode = cepMask(address.zipCode)
        address.city = response.data.address.city.name
        data.address = undefined
        setConsumer({ ...data, ...address })
      })
  }, [apiContext, properties.match.params.id])

  async function findCep(cep: string) {
    cep = cep.replace(/[^\d]+/g, '')

    if (cep.length > 8) {
      return
    }
    if (cep.length === 8) {
      const response = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (response.data.erro) {
        toast({
          duration: 2000,
          isClosable: true,
          status: 'error',
          title: 'CEP inválido'
        })
      } else {
        const { logradouro, bairro, localidade, uf } = response.data
        setConsumer({
          ...consumer,
          zipCode: cepMask(cep),
          street: logradouro,
          district: bairro,
          city: localidade,
          state: uf
        })
      }
    }
    if (cep.length < 8) {
      setConsumer({
        ...consumer,
        zipCode: cepMask(cep),
        street: '',
        district: '',
        city: '',
        state: ''
      })
    }
  }

  async function handleDeleteConsumer() {
    const response = await apiContext.delete(
      `/addressees/${properties.match.params.id}`
    )
    toast({
      title: response.data.message,
      isClosable: true,
      duration: 2000,
      status: 'info'
    })
    history.push('/clientes')
  }

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()

    const response = await apiContext.put(
      `/addressees/${properties.match.params.id}`,
      {
        nome: consumer.name,
        ie: consumer.stateRegistration,
        im: consumer.municipalRegistration,
        contribuinte: consumer.contribuition,
        cep: consumer.zipCode,
        logradouro: consumer.street,
        numero: consumer.number,
        complemento: consumer.complement,
        bairro: consumer.district,
        municipio: consumer.city,
        uf: consumer.state
      }
    )

    if (response.status === 400) {
      toast({
        title: response.data.message,
        duration: 2000,
        isClosable: true,
        status: 'error'
      })
      return
    }

    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    history.push(`/clientes/${properties.match.params.id}`)
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    let { name, value } = event.target

    if (name === 'zipCode') {
      findCep(value)
      return
    }
    if (name === 'municipalRegistration' || name === 'stateRegistration') {
      value = value.replace(/[^\d]+/g, '')
    }

    setConsumer({ ...consumer, [name]: value })
  }

  return (
    <ContainerBox>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Quer excluir este cliente?</ModalHeader>
          <ModalBody>Esta é uma ação que não pode ser desfeita.</ModalBody>
          <ModalCloseButton />
          <ModalFooter>
            <ChakraButton
              textTransform="uppercase"
              colorScheme="red"
              onClick={handleDeleteConsumer}
            >
              Sim, quero excluir!
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex justifyContent="space-between">
        <Heading size="lg">
          Alterar o cliente {documentMask(consumer.document)}
        </Heading>
        <IconButton
          onClick={onOpen}
          colorScheme="red"
          aria-label="Delete"
          icon={<DeleteIcon />}
        />
      </Flex>

      <Box as="form" onSubmit={handleSubmit}>
        <InputBlock
          marginTop
          name="name"
          value={consumer.name}
          onChange={handleInputChange}
          label="Nome"
          uppercase
        />
        <Tooltip label="Inscrição estadual" aria-label="Inscrição estadual">
          <InputBlock
            marginTop
            isRequired={consumer.contribuition === '1'}
            name="stateRegistration"
            value={consumer.stateRegistration}
            onChange={handleInputChange}
            label="Inscrição estadual"
          />
        </Tooltip>
        <Tooltip label="Inscrição municipal" aria-label="Inscrição municipal">
          <InputBlock
            marginTop
            name="municipalRegistration"
            value={consumer.municipalRegistration}
            onChange={handleInputChange}
            label="Inscrição municipal"
          />
        </Tooltip>
        <FormControl isRequired marginTop={2}>
          <FormLabel htmlFor="contribuition" marginLeft={2} marginBottom={-2}>
            Tipo de contribuição
          </FormLabel>
          <Select
            id="contribuition"
            name="contribuition"
            value={consumer.contribuition}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>
              Tipo de contribuição
            </option>
            <option value="1">Contribuinte com o ICMS</option>
            <option value="2">Isento</option>
            <option value="9">Não contribuinte</option>
          </Select>
        </FormControl>
        <InputBlock
          marginTop
          isRequired
          name="zipCode"
          value={consumer.zipCode}
          onChange={handleInputChange}
          label="CEP"
        />
        <InputBlock
          marginTop
          isRequired
          name="street"
          value={consumer.street}
          onChange={handleInputChange}
          label="Logradouro"
          uppercase
        />
        <InputBlock
          marginTop
          name="number"
          value={consumer.number}
          onChange={handleInputChange}
          label="Número"
          uppercase
        />
        <InputBlock
          marginTop
          name="complement"
          value={consumer.complement}
          onChange={handleInputChange}
          label="Complemento"
          uppercase
        />
        <InputBlock
          marginTop
          isRequired
          name="district"
          value={consumer.district}
          onChange={handleInputChange}
          label="Bairro"
          uppercase
        />
        <InputBlock
          marginTop
          isRequired
          name="city"
          value={consumer.city}
          onChange={handleInputChange}
          label="Municipio"
          uppercase
        />
        <InputBlock
          marginTop
          isRequired
          name="state"
          value={consumer.state}
          onChange={handleInputChange}
          label="Estado (UF)"
          uppercase
        />
        <Flex marginTop={5} justifyContent="flex-end">
          <ChakraButton
            type="button"
            variant="ghost"
            onClick={() => history.goBack()}
            textTransform="uppercase"
            fontWeight="300"
          >
            Cancelar
          </ChakraButton>
          <Button type="submit" marginLeft={5}>
            Atualizar
          </Button>
        </Flex>
      </Box>
    </ContainerBox>
  )
}

export default EditConsumer

import React, { useState, useEffect } from 'react'

interface TokenContextContract {
  token?: string | null
  updateToken(token: string | null): void
  clearToken(): void
}

export const TokenContext = React.createContext<TokenContextContract>({
  updateToken: () => null,
  clearToken: () => null
})

const TokenContextProvider: React.FunctionComponent = ({ children }) => {
  const [toastNow, setToastNow] = useState(false)
  const [token, setToken] = useState<string | null>()

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [])

  useEffect(() => {
    if (toastNow) {
      setTimeout(() => setToastNow(false), 5000)
    }
  }, [toastNow])

  function updateToken(token: string) {
    localStorage.setItem('token', token)
    setToken(token)
  }

  function clearToken() {
    if (token !== null) {
      if (toastNow === false) {
        setToastNow(true)
      }

      localStorage.clear()
      setToken(null)
    }
  }

  return (
    <TokenContext.Provider value={{ token, updateToken, clearToken }}>
      {children}
    </TokenContext.Provider>
  )
}

export default TokenContextProvider

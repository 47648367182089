import { extendTheme } from '@chakra-ui/react'

/* export const applicationTheme: DefaultTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif",
    mono: "'Roboto Mono', monospace"
  },
  radii: { ...theme.radii, md: '10px' },
  colors: {
    ...theme.colors,
    gray: { ...theme.colors.gray, 500: '#545454' },
    blue: { ...theme.colors.blue, 500: '#38b6ff' }
  }
} */

export const applicationTheme = extendTheme({
  fonts: {
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif",
    mono: "'Roboto Mono', monospace"
  },
  radii: { md: '10px' },
  colors: {
    gray: { 500: '#545454' },
    blue: { 500: '#38b6ff' }
  }
})

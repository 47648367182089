import React from 'react'
import { Box } from '@chakra-ui/react'

const ContainerBox: React.FC = ({ children }) => (
  <Box
    width="full"
    maxWidth="800px"
    marginX="auto"
    borderRadius="md"
    marginY={5}
    padding={5}
    shadow="md"
    borderWidth="1px"
    borderStyle="solid"
    borderColor="gray.100"
  >
    {children}
  </Box>
)

export default ContainerBox

import React, { useState, useEffect, useContext } from 'react'
import {
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  ListItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import history from '../../services/history'

import ContainerBox from '../../components/Container'
import List from '../../components/List'
import Button from '../../components/Button'
import ItemsList from './List'
import { CompanyContext } from '../../contexts/Company'

interface Item {
  id: string
  description: string
  value: number
}

const Itens: React.FC = () => {
  const companyContext = useContext(CompanyContext)
  const apiContext = useContext(ApiContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [products, setProducts] = useState<Item[]>()
  const [services, setServices] = useState<Item[]>()

  useEffect(() => {
    apiContext.get('items?type=products').then((response) => {
      setProducts(response.data)
    })
    apiContext.get('items?type=services').then((response) => {
      setServices(response.data)
    })
  }, [apiContext])

  function handleNewItemClick() {
    switch (companyContext.configurations.version) {
      case 'sales':
        history.push('/itens/novo/produto')
        break

      case 'services':
        history.push('/itens/novo/servico')
        break

      default:
        onOpen()
        break
    }
  }

  return (
    <ContainerBox>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent paddingBottom={5}>
          <ModalHeader>Que tipo de item deseja cadastrar?</ModalHeader>
          <ModalBody>
            <List removeMarginTop>
              <ListItem
                padding={3}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => history.push('/itens/novo/produto')}
              >
                Produto
              </ListItem>
              <ListItem
                padding={3}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => history.push('/itens/novo/servico')}
              >
                Serviço
              </ListItem>
            </List>
          </ModalBody>
          <ModalCloseButton _focus={{ outline: 'none' }} />
        </ModalContent>
      </Modal>

      <Heading fontWeight="300">Itens</Heading>
      <Flex justifyContent="flex-end">
        <Button onClick={handleNewItemClick}>Novo</Button>
      </Flex>
      {companyContext.configurations.version === 'sales' && (
        <ItemsList items={products} />
      )}
      {companyContext.configurations.version === 'services' && (
        <ItemsList items={services} />
      )}
      {companyContext.configurations.version === 'mixed' && (
        <Tabs variant="enclosed">
          <TabList>
            <Tab _focus={{ outline: 'none' }}>Produtos</Tab>
            <Tab _focus={{ outline: 'none' }}>Serviços</Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding={0}>
              <ItemsList items={products} />
            </TabPanel>
            <TabPanel padding={0}>
              <ItemsList items={services} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </ContainerBox>
  )
}

export default Itens

import React from 'react'
import { CircularProgress, Center, Heading } from '@chakra-ui/react'

interface TotalSpinnerProperties {
  actived: boolean
  legend?: string
}

const TotalSpinner: React.FC<TotalSpinnerProperties> = ({
  actived,
  legend
}) => {
  if (!actived) {
    return null
  }

  return (
    <Center
      height="100vh"
      width="100vw"
      position="fixed"
      top={0}
      left={0}
      zIndex={999}
      backgroundColor="rgba(0,0,0,0.7)"
      display="flex"
      flexDirection="column"
    >
      <CircularProgress isIndeterminate size="100px" thickness="3px" />
      {legend && (
        <Heading color="white" marginTop={4} fontWeight="300">
          {legend}
        </Heading>
      )}
    </Center>
  )
}

export default TotalSpinner

import React from 'react'
import { Text, Heading } from '@chakra-ui/react'

const Logo: React.FC = () => {
  return (
    <Heading
      fontFamily="'Baloo 2', cursive"
      fontWeight="800"
      color="gray.500"
      fontSize="5xl"
    >
      em
      <Text as="span" fontFamily="'Baloo 2', cursive" color="blue.500">
        nuvem
      </Text>
    </Heading>
  )
}

export default Logo

import React, { useState, useContext, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button as ChakraButton,
  Heading,
  Flex,
  Spinner,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import { ncmMask, moneyMask } from '../../helpers/mask'
import history from '../../services/history'

import ContainerBox from '../../components/Container'

interface Item {
  code: string
  description: string
  ncm: string
  unity: string
  value: number
}

const ShowItem: React.FC<RouteComponentProps<{ id?: string }>> = (
  properties
) => {
  const itemId = properties.match.params.id
  const apiContext = useContext(ApiContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const [deleting, setDeleting] = useState(false)
  const [item, setItem] = useState<Item>()

  useEffect(() => {
    apiContext.get(`/items/${itemId}`).then((response) => {
      setItem(response.data)
    })
  }, [apiContext, itemId])

  async function handleDeleteItem() {
    setDeleting(true)
    const response = await apiContext.delete(`/items/${itemId}`)
    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    history.push('/itens')
  }

  return (
    <ContainerBox>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deseja excluir este item?</ModalHeader>
          <ModalBody>Esta é uma ação que não pode ser desfeita.</ModalBody>
          <ModalCloseButton _focus={{ outline: 'none' }} />
          <ModalFooter>
            <ChakraButton
              isLoading={deleting}
              textTransform="uppercase"
              colorScheme="red"
              onClick={handleDeleteItem}
              _focus={{ outline: 'none' }}
            >
              Sim, quero excluir!
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {!item ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Heading size="lg">{item.description}</Heading>
          {item.code && <Text>Código: {item.code}</Text>}
          {item.ncm !== '00000000' && <Text>NCM: {ncmMask(item.ncm)}</Text>}
          <Text>Unidade de medição: {item.unity}</Text>
          <Text fontWeight="700">Valor unitário: {moneyMask(item.value)}</Text>
        </>
      )}
      <Flex justifyContent="flex-end">
        <IconButton
          colorScheme="red"
          aria-label="excluir item"
          icon={<DeleteIcon />}
          _focus={{ outline: 'none' }}
          onClick={onOpen}
        />
      </Flex>
    </ContainerBox>
  )
}

export default ShowItem

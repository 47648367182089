import React, { useContext } from 'react'
import { ApiContext } from '../../../contexts/Api'
import { useToast, Box, Checkbox } from '@chakra-ui/react'
import { CompanyContext } from '../../../contexts/Company'

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface ISSRetentionProperties {
  typeNf: string
  sketchId: string
  ISSRetention: boolean
  updateISSRetention: (ISSRetention: boolean) => void
}

const ISSRetention: React.FC<ISSRetentionProperties> = (properties) => {
  const companyContext = useContext(CompanyContext)
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  async function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    properties.updateISSRetention(event.target.checked)

    const response = await apiContext.put(
      `/sketches/${properties.sketchId}/retencao-iss`,
      {
        retencaoISS: event.target.checked
      }
    )

    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
  }

  if (
    properties.typeNf !== 'NFSe' ||
    !companyContext.configurations.ISSRetention
  ) {
    return null
  }

  return (
    <Box marginTop={4}>
      <Checkbox
        size="lg"
        isChecked={properties.ISSRetention}
        onChange={handleInputChange}
        outline="none"
      >
        Reter ISS
      </Checkbox>
    </Box>
  )
}

export default ISSRetention

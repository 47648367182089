import React, { useState, useContext } from 'react'
import { useToast, Heading, Box, Flex } from '@chakra-ui/react'

import history from '../../services/history'
import { ApiContext } from '../../contexts/Api'

import ContainerBox from '../../components/Container'
import Button from '../../components/Button'
import InputBlock from '../../components/InputBlock'

const ChangePassword: React.FC = () => {
  const toast = useToast()
  const apiContext = useContext(ApiContext)

  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: ''
  })

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()

    const { oldPassword, newPassword, newPasswordAgain } = form
    if (newPassword !== newPasswordAgain) {
      toast({
        title: 'Senhas diferentes',
        description:
          'A senha do campo de confirmação está diferente da nova senha',
        duration: 5000,
        isClosable: true,
        status: 'error'
      })
      return
    }

    const response = await apiContext.put('/user/password', {
      oldPassword,
      newPassword
    })

    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    history.push('/configuracoes')
  }

  return (
    <ContainerBox>
      <Heading fontWeight="300">Alterar senha</Heading>
      <Box as="form" onSubmit={handleSubmit} marginTop={5}>
        <InputBlock
          isRequired
          name="oldPassword"
          type="password"
          value={form.oldPassword}
          onChange={handleInputChange}
          label="Senha atual"
        />
        <InputBlock
          marginTop
          isRequired
          name="newPassword"
          type="password"
          value={form.newPassword}
          onChange={handleInputChange}
          label="Nova senha"
        />
        <InputBlock
          marginTop
          isRequired
          name="newPasswordAgain"
          type="password"
          value={form.newPasswordAgain}
          onChange={handleInputChange}
          label="Confirme a nova senha"
        />
        <Flex marginTop={5} justifyContent="flex-end">
          <Button
            type="button"
            backgroundColor="white"
            _hover={{ backgroundColor: 'gray.100' }}
            color="black"
            onClick={() => history.push('/configuracoes')}
          >
            Cancelar
          </Button>
          <Button type="submit" marginLeft={2}>
            Alterar
          </Button>
        </Flex>
      </Box>
    </ContainerBox>
  )
}

export default ChangePassword

import React, { useContext, useState } from 'react'
import { Grid, Flex, Heading, Image, Button, useToast } from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import { TokenContext } from '../../contexts/Token'
import { UserTypeContext } from '../../contexts/UserType'

import LogoImage from '../../assets/logo.png'
import Logo from '../../components/Logo'
import InputBlock from '../../components/InputBlock'

const LoginPage: React.FC = () => {
  const api = useContext(ApiContext)
  const token = useContext(TokenContext)
  const userType = useContext(UserTypeContext)
  const toast = useToast()

  const [credentials, setCredentials] = useState({
    update: true,
    email: '',
    password: ''
  })

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()

    const response = await api.post('/login', credentials)
    if (response.status > 200) {
      toast({
        title: response.data.message,
        status: 'error',
        duration: 2500,
        isClosable: true
      })
      return
    }
    toast({
      title: response.data.message,
      status: 'success',
      duration: 2500,
      isClosable: true
    })
    token.updateToken(response.data.token)
    userType.setType(response.data.user.type)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    setCredentials({ ...credentials, [name]: value })
  }

  return (
    <div>
      <Grid
        as="main"
        height="100vh"
        templateColumns="1fr 400px 400px 1fr"
        templateRows="1fr 300px 1fr"
        templateAreas="
          '. .    .    .'
          '. logo form .'
          '. .    .    .'
        "
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          gridArea="logo"
          as="aside"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={LogoImage}
            alt="emnuvem tecnologias"
            height="3xs"
            width="3xs"
          />
          <Heading color="gray.500" fontWeight="300">
            emissor
          </Heading>
          <Logo />
        </Flex>

        <Flex
          as="form"
          gridArea="form"
          height="100%"
          backgroundColor="gray.100"
          flexDirection="column"
          padding={8}
          onSubmit={handleSubmit}
          justifyContent="center"
        >
          <InputBlock
            isRequired
            name="email"
            type="email"
            value={credentials.email}
            label="E-mail"
            onChange={handleInputChange}
          />
          <InputBlock
            isRequired
            marginTop
            name="password"
            type="password"
            value={credentials.password}
            label="Senha"
            onChange={handleInputChange}
          />

          <Button
            type="submit"
            backgroundColor="gray.500"
            marginTop={4}
            color="white"
            _hover={{ backgroundColor: 'blue.500' }}
            textTransform="uppercase"
          >
            Entrar
          </Button>
        </Flex>
      </Grid>
    </div>
  )
}

export default LoginPage

import React, { useState, useContext } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { Box, Flex, Spinner, Heading, useToast } from '@chakra-ui/react'

import { SketchContext } from '.'
import { ApiContext } from '../../../contexts/Api'

import Button from '../../../components/Button'

const AdditionalInformation: React.FC = () => {
  const sketchContext = useContext(SketchContext)
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [additionalInformation, setAdditionalInformation] = useState(
    sketchContext.sketch
      ? sketchContext.sketch.information.additionalInformation
      : undefined
  )

  async function updateAdditionalInformation() {
    setLoading(true)
    const response = await apiContext.put(
      `/sketches/${sketchContext.sketch?.id}/additional-information`,
      {
        additionalInformation
      }
    )
    toast({
      title: response.data.message,
      isClosable: true,
      duration: 2000,
      status: 'info'
    })
    setLoading(false)
    setUpdate(false)
  }

  if (additionalInformation === undefined) {
    return (
      <Flex height={100} justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Heading marginTop={10} size="lg" fontWeight="300">
          Informações complementares
        </Heading>
        <Button
          isLoading={loading}
          size="sm"
          marginBottom={1}
          onClick={() => {
            if (update) {
              updateAdditionalInformation()
            } else {
              setUpdate(!update)
            }
          }}
        >
          {update ? 'Salvar' : 'Alterar'}
        </Button>
      </Flex>

      {update ? (
        <ReactTextareaAutosize
          style={{
            padding: '12px',
            textAlign: 'justify',
            textTransform: 'uppercase',
            width: '100%',
            outline: 'none',
            border: '1px solid #E2E8F0',
            borderRadius: '10px',
            resize: 'none',
            marginBottom: '-7px'
          }}
          value={additionalInformation}
          onChange={(event) => setAdditionalInformation(event.target.value)}
        />
      ) : (
        <Box
          textTransform="uppercase"
          textAlign="justify"
          minHeight="50px"
          width="full"
          border="1px solid"
          borderColor="gray.50"
          borderRadius="md"
          padding={3}
          cursor="default"
        >
          {additionalInformation}
        </Box>
      )}
    </>
  )
}

export default AdditionalInformation

import React, { useContext, useState, useEffect, useRef } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { Flex, Spinner, useToast, useDisclosure } from '@chakra-ui/react'

import { SketchContext } from '.'
import { ApiContext } from '../../../contexts/Api'
import history from '../../../services/history'

import IconButton from '../../../components/IconButton'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import TotalSpinner from '../../../components/TotalSpinner'

const SketchOptions: React.FC = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const sketchContext = useContext(SketchContext)
  const apiContext = useContext(ApiContext)

  const [transmitting, setTransmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [disabledButtons, setDisabledButtons] = useState(false)

  const errorTramitionToastRef = useRef<string | number | undefined>()

  useEffect(() => {
    if (errorTramitionToastRef.current) {
      toast.close(errorTramitionToastRef.current)
    }
    if (!sketchContext.sketch) {
      setDisabledButtons(true)
      return
    }
    setDisabledButtons(
      sketchContext.sketch.items.length === 0 ||
        sketchContext.sketch.addressee === undefined
    )
  }, [sketchContext.sketch, toast])

  async function handleDelete() {
    if (sketchContext.sketch) {
      setDeleting(true)
      const response = await apiContext.delete(
        `/sketches/${sketchContext.sketch.id}`
      )
      toast({
        duration: 2000,
        isClosable: true,
        status: 'info',
        title: response.data.message
      })
      setDeleting(false)
      history.push('/rascunhos')
    }
  }

  async function preview() {
    if (sketchContext.sketch) {
      const response = await apiContext.get(
        `/sketches/${sketchContext.sketch.id}/da`
      )
      const previewWindow = window.open(sketchContext.sketch.typeNf, '_blank')
      if (previewWindow === null) {
        throw new Error(
          'Este erro nunca vi sedo chamado, sempre existe a tela...'
        )
      }
      previewWindow.document.write(`
        <html>
          <head>
            <title>DA${sketchContext.sketch.typeNf}</title>
            <style>
              body {
                margin: 0px !important;
              }
            </style>
          </head>
          <body>
            <embed width=100% height=100% type="application/pdf"
              src="data:application/pdf;base64,${escape(
                response.data.base64
              )}"></embed>
          </body>
        </html>
      `)
      previewWindow.document.close()
    }
  }

  async function transmitter() {
    if (sketchContext.sketch) {
      setTransmitting(true)
      const response = await apiContext.patch(
        `/sketches/${sketchContext.sketch.id}/transmitter`
      )

      if (response.status === 400) {
        errorTramitionToastRef.current = toast({
          title: response.data.message,
          description: response.data.motivo,
          status: 'error',
          isClosable: true,
          duration: 30000
        })
        setTransmitting(false)
        return
      }

      toast({
        title: response.data.message,
        status: 'success',
        isClosable: true,
        duration: 2000
      })
      setTransmitting(false)
      history.push('/notas-fiscais')
    }
  }

  if (!sketchContext.sketch) {
    return (
      <Flex height={100} justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <>
      <TotalSpinner actived={transmitting} legend="transmitindo..." />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Deseja excluir este racunho?"
        footer={
          <Button
            isLoading={deleting}
            textTransform="uppercase"
            colorScheme="red"
            onClick={handleDelete}
            _focus={{ outline: 'none' }}
          >
            Sim, quero excluir!
          </Button>
        }
      >
        Esta é uma ação que não pode ser desfeita.
      </Modal>
      <Flex marginTop={5} justifyContent="space-between">
        <IconButton
          aria-label="Excluir rascunho"
          colorScheme="red"
          onClick={onOpen}
          icon={<DeleteIcon />}
        />

        <Flex>
          <Button isDisabled={disabledButtons} onClick={preview}>
            Prévia
          </Button>
          <Button
            isDisabled={disabledButtons}
            marginLeft={5}
            onClick={transmitter}
          >
            Transmitir
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default SketchOptions

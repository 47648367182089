import React from 'react'
import { Router } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import TokenContextProvider from './contexts/Token'
import ApiContextProvider from './contexts/Api'
import Routes from './routes'
import history from './services/history'
import { applicationTheme } from './theme'
import UserTypeContextProvider from './contexts/UserType'

const App: React.FC = () => (
  <ChakraProvider theme={applicationTheme}>
    <TokenContextProvider>
      <UserTypeContextProvider>
        <ApiContextProvider>
          <Router history={history}>
            <Routes />
          </Router>
        </ApiContextProvider>
      </UserTypeContextProvider>
    </TokenContextProvider>
  </ChakraProvider>
)

export default App

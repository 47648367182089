import React from 'react'
import { List as ChakraList, ListProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

interface ListProperties extends ListProps {
  removeMarginTop?: boolean
}

const StyledList = styled(ChakraList)`
  border: 1px solid #e2e8f0;
  list-style-type: none;
  border-radius: 8px;
  margin-top: ${(properties) => (properties.removeMarginTop ? '0' : '20px')};

  li + li {
    border-top: 1px solid #e2e8f0;
  }

  li:first-child {
    border-radius: 8px 8px 0 0;
  }
  li:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const List: React.FC<ListProperties> = (properties) => (
  <StyledList removeMarginTop={properties.removeMarginTop}>
    {properties.children}
  </StyledList>
)

export default List

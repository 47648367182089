import React, { useState, useEffect } from 'react'

interface UserTypeContextContract {
  type: string | null
  setType: (type: string) => void
}

export const UserTypeContext = React.createContext<UserTypeContextContract>({
  type: null,
  setType: () => null
})

const UserTypeContextProvider: React.FC = ({ children }) => {
  const [type, setType] = useState<string | null>(null)

  useEffect(() => {
    setType(localStorage.getItem('userType'))
  }, [])

  function handleSetType(type: string) {
    localStorage.setItem('userType', type)
    setType(type)
  }

  return (
    <UserTypeContext.Provider value={{ type, setType: handleSetType }}>
      {children}
    </UserTypeContext.Provider>
  )
}

export default UserTypeContextProvider

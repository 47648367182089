import React, { useContext, useState, useEffect } from 'react'
import {
  Heading,
  FormControl,
  FormLabel,
  Select,
  Box,
  Flex,
  useToast,
  Checkbox
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import history from '../../services/history'

import ContainerBox from '../../components/Container'
import Button from '../../components/Button'
import { CompanyContext, CompanyConfigurations } from '../../contexts/Company'
import InputBlock from '../../components/InputBlock'

const UpdateRegistration: React.FC = () => {
  const companyContext = useContext(CompanyContext)
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<CompanyConfigurations>(
    companyContext.configurations
  )

  useEffect(() => {
    async function effect() {
      await companyContext.updateConfigurations()
    }
    effect()
  }, [])

  useEffect(() => {
      setForm(companyContext.configurations)
  }, [companyContext.configurations])

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)

    const response = await apiContext.put('/companies', form)
    await companyContext.updateConfigurations()

    toast({
      title: response.data.feedback,
      status: 'success',
      isClosable: true,
      duration: 2000
    })

    setLoading(false)
    history.push('/rascunhos')
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const { name } = event.target
    let value: string | boolean = event.target.value

    if (name === 'orders') {
      value = !form.orders
    }
    if (name === 'variableServiceCode') {
      value = !form.variableServiceCode
    }
    if (name === 'ISSRetention') {
      value = !form.ISSRetention
    }
    if (name === 'itemsWithCodes') {
      value = !form.itemsWithCodes
    }

    setForm({ ...form, [name]: value })
  }

  return (
    <ContainerBox>
      <Heading fontWeight="300">Atualizar cadastro da empresa</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel htmlFor="version" marginLeft={2} marginBottom={-3}>
            Tipo de empresa
          </FormLabel>
          <Select
            id="version"
            name="version"
            marginTop={2}
            value={form.version}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>
              Selecione
            </option>
            <option value="sales">Comércio</option>
            <option value="services">Prestadora de serviços</option>
            <option value="mixed">Mista (Comércio e Serviços)</option>
          </Select>
        </FormControl>
        <Flex flexDirection="column">
          <Checkbox
            size="lg"
            name="itemsWithCodes"
            isChecked={Boolean(form.itemsWithCodes)}
            marginTop={5}
            onChange={handleInputChange}
          >
            Itens com código
          </Checkbox>
          <Checkbox
            size="lg"
            name="orders"
            isChecked={Boolean(form.orders)}
            marginTop={5}
            onChange={handleInputChange}
          >
            Serviços com código de pedido
          </Checkbox>
          <Checkbox
            size="lg"
            name="variableServiceCode"
            isChecked={Boolean(form.variableServiceCode)}
            marginTop={5}
            onChange={handleInputChange}
          >
            Código de serviços variável
          </Checkbox>
          <Checkbox
            size="lg"
            name="ISSRetention"
            isChecked={Boolean(form.ISSRetention)}
            marginTop={5}
            onChange={handleInputChange}
          >
            Retenção de ISS
          </Checkbox>
          <InputBlock
            isRequired
            marginTop
            type="number"
            name="decimalLimit"
            value={form.decimalLimit}
            label="Número de casas decimais"
            onChange={handleInputChange}
          />
        </Flex>

        <Flex marginTop={5} justifyContent="flex-end">
          <Button isLoading={loading} type="submit">
            Atualizar
          </Button>
        </Flex>
      </Box>
    </ContainerBox>
  )
}

export default UpdateRegistration

import React, { useContext, useState } from 'react'
import {
  Text,
  ListItem,
  Flex,
  IconButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalFooter,
  Button as ChakraButton
} from '@chakra-ui/react'

import List from '../../../../components/List'
import { moneyMask } from '../../../../helpers/mask'
import { DeleteIcon } from '@chakra-ui/icons'
import { ApiContext } from '../../../../contexts/Api'
import { SketchContext } from '..'

export interface SketchItem {
  id: string
  code: string
  description: string
  ncm: string
  serviceCode: string
  unityValue: number
  unity: string
  amount: string | number
  value: string | number
  order: string
  orderItemNumber: string
}

interface ListOfItemsProperties {
  sketchId: string
  items: SketchItem[]
}

const ListOfItems: React.FC<ListOfItemsProperties> = ({ sketchId, items }) => {
  const sketchContext = useContext(SketchContext)
  const apiContext = useContext(ApiContext)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [loading, setLoading] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<SketchItem | null>(null)

  async function deleteItem() {
    if (itemToDelete) {
      setLoading(true)
      const response = await apiContext.delete(
        `/sketches/${sketchId}/items/${itemToDelete.id}`
      )

      toast({
        title: response.data.message,
        isClosable: true,
        duration: 2000,
        status: 'info'
      })

      const newItems = items.filter((i) => {
        return i.id !== itemToDelete.id
      })

      if (sketchContext.sketch) {
        sketchContext.setSketch({
          ...sketchContext.sketch,
          items: newItems
        })
      }

      setLoading(false)
      onClose()
    }
  }

  if (items.length === 0) {
    return <Text>Nenhum item até o momento</Text>
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Realmente deseja excluir este item?</ModalHeader>
          <ModalBody paddingBottom={4}>{itemToDelete?.description}</ModalBody>
          <ModalCloseButton _focus={{ outline: 'none' }} />
          <ModalFooter>
            <ChakraButton
              isLoading={loading}
              textTransform="uppercase"
              colorScheme="red"
              onClick={deleteItem}
              _focus={{ outline: 'none' }}
            >
              Sim, quero excluir!
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <List removeMarginTop>
        {items.map((item) => (
          <ListItem
            key={item.id}
            padding={3}
            _hover={{ backgroundColor: 'gray.100' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              as="span"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {item.description}
            </Text>

            <Flex alignItems="center" marginLeft={4}>
              <Text as="span" whiteSpace="nowrap">
                {moneyMask(Number(item.value))}
              </Text>
              <IconButton
                marginLeft={3}
                marginY={-5}
                size="sm"
                colorScheme="red"
                aria-label="Excluir item"
                _focus={{ outline: 'none' }}
                icon={<DeleteIcon />}
                onClick={() => {
                  setItemToDelete(item)
                  onOpen()
                }}
              />
            </Flex>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default ListOfItems

import React, { useContext } from 'react'
import { MaskedInputProps } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import InputMask from './InputMask'
import { CompanyContext } from '../contexts/Company'

const InputMoney: React.FC<MaskedInputProps> = (properties) => {
  const companyContext = useContext(CompanyContext)

  const numberMask = createNumberMask({
    prefix: 'R$ ',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: companyContext.configurations.decimalLimit
  })

  return <InputMask {...properties} mask={numberMask} />
}

export default InputMoney

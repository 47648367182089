import React from 'react'
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'

const Button: React.FC<ButtonProps> = (properties) => {
  if (properties.colorScheme !== undefined) {
    return (
      <ChakraButton
        textTransform="uppercase"
        fontWeight="300"
        _focus={{ outline: 'none' }}
        {...properties}
      >
        {properties.children}
      </ChakraButton>
    )
  }

  return (
    <ChakraButton
      textTransform="uppercase"
      color="white"
      backgroundColor="gray.500"
      transition="background 0.3s, color 0.3s"
      fontWeight="300"
      _focus={{ outline: 'none' }}
      _hover={{ backgroundColor: 'blue.500', color: 'black' }}
      {...properties}
    >
      {properties.children}
    </ChakraButton>
  )
}

export default Button

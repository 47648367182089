import React, {
  useState,
  useContext,
  FormEvent,
  ChangeEvent,
  useEffect
} from 'react'
import {
  Heading,
  Box,
  useToast,
  Flex,
  Select,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'

import ContainerBox from '../../components/Container'
import { documentMask, cepMask } from '../../helpers/mask'
import axios from 'axios'
import Button from '../../components/Button'
import history from '../../services/history'
import InputBlock from '../../components/InputBlock'

function CreateAddressee() {
  const [type, setType] = useState<string | undefined>()
  const [form, setForm] = useState({
    document: '',
    name: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    stateInscription: '',
    municipalInscription: '',
    contribuitionType: ''
  })

  const toast = useToast()
  const api = useContext(ApiContext)

  useEffect(() => {
    const documentLength = form.document.replace(/\D/gim, '').length
    if (documentLength === 11) {
      setType('PF')
    } else if (documentLength === 14) {
      setType('PJ')
    } else {
      setType(undefined)
    }
  }, [form.document])

  useEffect(() => {
    setForm({
      document: form.document,
      name: '',
      cep: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      stateInscription: '',
      municipalInscription: '',
      contribuitionType: ''
    })
  }, [type, form.document])

  async function findCep(cep: string) {
    cep = cep.replace(/[^\d]+/g, '')

    if (cep.length > 8) {
      return
    }
    if (cep.length === 8) {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (response.data.erro) {
        toast({
          duration: 2000,
          isClosable: true,
          status: 'error',
          title: 'CEP inválido'
        })
      } else {
        const { logradouro, bairro, localidade, uf } = response.data
        setForm({
          ...form,
          cep: cepMask(cep),
          street: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: uf
        })
      }
    }
    if (cep.length < 8) {
      setForm({
        ...form,
        cep: cepMask(cep),
        street: '',
        neighborhood: '',
        city: '',
        state: ''
      })
    }
  }

  async function handleSubmit(event: FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()
    if (!type) {
      toast({
        title: 'Preencha com um CNPJ ou um CPF',
        duration: 2000,
        isClosable: true,
        status: 'error'
      })
      return
    }

    const response = await api.post(`/addressees?type=${type}`, {
      document: form.document,
      ie: form.stateInscription,
      im: form.municipalInscription,
      contribuinte: form.contribuitionType,
      nome: form.name,
      logradouro: form.street,
      numero: form.number,
      complemento: form.complement,
      bairro: form.neighborhood,
      municipio: form.city,
      uf: form.state,
      cep: form.cep
    })
    if (response.status === 400) {
      toast({
        title: response.data.message,
        duration: 2000,
        isClosable: true,
        status: 'error'
      })
      return
    }

    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    history.push('/clientes')
  }

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    let { name, value } = event.target

    if (name === 'document') {
      value = documentMask(value, form.document)
    }
    if (name === 'cep') {
      findCep(value)
      return
    }
    if (name === 'municipalInscription' || name === 'stateInscription') {
      value = value.replace(/[^\d]+/g, '')
    }

    setForm({ ...form, [name]: value })
  }

  return (
    <ContainerBox>
      <Heading fontWeight="300">Novo cliente</Heading>
      <Box as="form" onSubmit={handleSubmit} marginTop={3}>
        <InputBlock
          isRequired
          name="document"
          value={form.document}
          onChange={handleInputChange}
          label="CNPJ ou CPF"
        />
        {type === 'PJ' && (
          <>
            <InputBlock
              isRequired={form.contribuitionType === '1'}
              marginTop
              name="stateInscription"
              value={form.stateInscription}
              onChange={handleInputChange}
              label="Inscrição Estadual"
            />
            <InputBlock
              marginTop
              name="municipalInscription"
              value={form.municipalInscription}
              onChange={handleInputChange}
              label="Inscrição Municipal"
            />
            <FormControl isRequired marginTop={2}>
              <FormLabel
                htmlFor="contribuitionType"
                marginLeft={2}
                marginBottom={-1}
              >
                Tipo de empresa
              </FormLabel>
              <Select
                id="contribuitionType"
                name="contribuitionType"
                value={form.contribuitionType}
                onChange={handleInputChange}
              >
                <option value="" disabled hidden>
                  Selecione
                </option>
                <option value="1">Contribuinte com o ICMS</option>
                <option value="2">Isento</option>
                <option value="9">Não contribuinte</option>
              </Select>
            </FormControl>
          </>
        )}
        {type === 'PF' && (
          <>
            <InputBlock
              isRequired
              marginTop
              name="name"
              value={form.name}
              onChange={handleInputChange}
              label="Nome"
              uppercase
            />
            <InputBlock
              isRequired
              marginTop
              name="cep"
              value={form.cep}
              onChange={handleInputChange}
              label="CEP"
            />
            <InputBlock
              isRequired
              marginTop
              name="street"
              value={form.street}
              onChange={handleInputChange}
              label="Logradouro"
              uppercase
            />
            <InputBlock
              marginTop
              name="number"
              value={form.number}
              onChange={handleInputChange}
              label="Numero"
              uppercase
            />
            <InputBlock
              marginTop
              name="complement"
              value={form.complement}
              onChange={handleInputChange}
              label="Complemento"
              uppercase
            />
          </>
        )}
        {type && (
          <Flex justifyContent="flex-end" marginTop={5}>
            <Button type="submit">Salvar</Button>
          </Flex>
        )}
      </Box>
    </ContainerBox>
  )
}

export default CreateAddressee

import React, { useState, useContext, useEffect } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Heading,
  Flex,
  IconButton,
  Input,
  ListItem,
  Spinner
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import { moneyMask } from '../../helpers/mask'
import history from '../../services/history'

import ContainerBox from '../../components/Container'
import List from '../../components/List'

interface Invoice {
  canceled: boolean
  number: string
  addressee: string
  value: number
}

const Invoices: React.FC = () => {
  const date = new Date()

  let month = (date.getMonth() + 1).toString()
  if (month.length === 1) {
    month = `0${month}`
  }
  const year = date.getFullYear()

  const [invoices, setInvoices] = useState<Invoice[] | undefined>()
  const [total, setTotal] = useState(0)
  const [periodInput, setPeriodInput] = useState(`${month}-${year}`)
  const [period, setPeriod] = useState({
    previous: '',
    atualy: `${month}-${year}`,
    next: ''
  })

  const apiContext = useContext(ApiContext)

  function handlePrevious() {
    setTotal(0)
    setInvoices(undefined)
    setPeriod({ ...period, atualy: period.previous })
  }

  function handleNext() {
    setTotal(0)
    setInvoices(undefined)
    setPeriod({ ...period, atualy: period.next })
  }

  /* function handlePeriodChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
  } */

  useEffect(() => {
    async function getInvoices() {
      const response = await apiContext.get<{
        invoices: Invoice[]
        period: { previous: string; atualy: string; next: string }
      }>(`/invoices?period=${period.atualy}`)

      setPeriod(response.data.period)
      setPeriodInput(response.data.period.atualy)
      setInvoices(response.data.invoices)
      let sum = 0
      response.data.invoices.forEach((invoice) => {
        if (!invoice.canceled) {
          sum += Number(invoice.value)
        }
      })
      setTotal(Math.round(sum * 100) / 100)
    }
    getInvoices()
  }, [period.atualy, apiContext])

  return (
    <ContainerBox>
      <Flex justifyContent="space-between">
        <Heading fontWeight="300">Notas fiscais</Heading>
        <Flex>
          <IconButton
            aria-label="mês anterior"
            icon={<ChevronLeftIcon />}
            border="1px solid"
            borderColor="gray.500"
            borderRadius="8px 0 0 8px"
            backgroundColor="gray.500"
            color="white"
            _hover={{ backgroundColor: 'blue.500', color: 'black' }}
            _focus={{ outline: 'none' }}
            onClick={handlePrevious}
          />
          <Input
            value={periodInput}
            borderRadius="0"
            width="12ch"
            borderX="none"
            borderColor="gray.500"
            fontFamily="'Roboto Mono', monospace"
            textAlign="center"
          />
          <IconButton
            aria-label="proximo mês"
            icon={<ChevronRightIcon />}
            border="1px solid"
            borderColor="gray.500"
            borderRadius="0 8px 8px 0"
            backgroundColor="gray.500"
            color="white"
            _hover={{ backgroundColor: 'blue.500', color: 'black' }}
            _focus={{ outline: 'none' }}
            onClick={handleNext}
            isDisabled={!period.next}
          />
        </Flex>
      </Flex>
      {total !== 0 && <p>Total emitido neste mês: {moneyMask(total)}</p>}
      {invoices === undefined || invoices.length === 0 ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          {invoices === undefined ? (
            <Spinner size="xl" />
          ) : (
            <p>Nenhuma nota fiscal foi emitida neste mês</p>
          )}
        </Flex>
      ) : (
        <List>
          {invoices.map((invoice) => (
            <ListItem
              key={invoice.number}
              display="flex"
              justifyContent="space-between"
              padding={3}
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.100' }}
              onClick={() => history.push(`/notas-fiscais/${invoice.number}`)}
              textDecoration={invoice.canceled ? 'line-through' : 'none'}
            >
              {invoice.number} - {invoice.addressee}
              <span>{moneyMask(invoice.value)}</span>
            </ListItem>
          ))}
        </List>
      )}
    </ContainerBox>
  )
}

export default Invoices

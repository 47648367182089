import React, { useState, useContext } from 'react'
import { useToast } from '@chakra-ui/react'

import { ApiContext } from '../../../contexts/Api'
import { validateDate, formatDate } from '../../../helpers/date'
import InputMask from '../../../components/InputMask'

interface SketchDateProperties {
  sketchId: string
  date: string
  updateDate: (date: string) => void
}

const SketchDate: React.FC<SketchDateProperties> = (properties) => {
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  const [date, setDate] = useState(formatDate(properties.date))

  function clearInput() {
    setDate('')
  }

  function handleOnBlurInput() {
    setDate(formatDate(properties.date))
    properties.updateDate(properties.date)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    setDate(value)

    const lengthValue = value.replace(/_|\//g, '').length

    if (lengthValue === 8) {
      const validDate = validateDate(value)
      if (validDate) {
        apiContext
          .put(`/sketches/${properties.sketchId}/date`, { date: validDate })
          .then((response) => {
            properties.updateDate(value)
            toast({
              title: response.data.message,
              duration: 2000,
              isClosable: true,
              status: 'success'
            })
          })
      } else {
        toast({
          title: 'Data inválida',
          duration: 2000,
          isClosable: true,
          status: 'error'
        })
      }
    }
  }

  return (
    <InputMask
      style={{
        width: '14ch',
        textAlign: 'center',
        fontFamily: '"Roboto Mono", monospace'
      }}
      name="date"
      value={date}
      onFocus={clearInput}
      onBlur={handleOnBlurInput}
      onChange={handleInputChange}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

export default SketchDate

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi'
import {
  Box,
  Flex,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button as ChakraButton,
  ModalCloseButton
} from '@chakra-ui/react'

import history from '../../services/history'
import { TokenContext } from '../../contexts/Token'
import { UserTypeContext } from '../../contexts/UserType'

import NavbarButton from './Button'
import Button from '../Button'

interface NavbarComponentProperties {
  menuActived?: string
}

const NavbarComponent: React.FC<NavbarComponentProperties> = ({
  menuActived
}) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const tokenContext = useContext(TokenContext)
  const userTypeContext = useContext(UserTypeContext)

  function handleLogout() {
    onClose()
    toast({
      title: 'Sessão encerrada',
      status: 'info',
      duration: 2500,
      isClosable: true
    })
    tokenContext.clearToken()
  }

  return (
    <Box
      bg="gray.500"
      display="flex"
      boxShadow="md"
      justifyContent="space-between"
      height={12}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent paddingBottom={5}>
          <ModalHeader> </ModalHeader>
          <ModalBody display="flex" flexDirection="column">
            <Button
              onClick={() => {
                onClose()
                history.push('/configuracoes')
              }}
            >
              Configurações
            </Button>
            <ChakraButton
              colorScheme="red"
              marginTop={6}
              fontWeight="300"
              textTransform="uppercase"
              onClick={handleLogout}
            >
              Sair
            </ChakraButton>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>

      <Flex>
        <Link to="/rascunhos">
          <NavbarButton actived={menuActived === 'sketches'}>
            Rascunhos
          </NavbarButton>
        </Link>
        <Link to="/notas-fiscais">
          <NavbarButton actived={menuActived === 'invoices'}>
            Notas Fiscais
          </NavbarButton>
        </Link>
      </Flex>
      <Flex>
        <Link to="/clientes">
          <NavbarButton actived={menuActived === 'consumers'}>
            Clientes
          </NavbarButton>
        </Link>
        <Link to="/itens">
          <NavbarButton actived={menuActived === 'items'}>Itens</NavbarButton>
        </Link>
        {userTypeContext.type !== 'User' && (
          <Link to="/emissores">
            <NavbarButton actived={menuActived === 'companies'}>
              Emissores
            </NavbarButton>
          </Link>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingX={4}
          cursor="pointer"
          color="white"
          _focus={{ outline: 'none' }}
          _hover={{ backgroundColor: 'blue.500' }}
          onClick={onOpen}
        >
          <HiMenu size={20} />
        </Box>
      </Flex>
    </Box>
  )
}

export default NavbarComponent

import React from 'react'
import { Box } from '@chakra-ui/react'

interface NavbarButtonProperties {
  actived: boolean
}

const NavbarButton: React.FC<NavbarButtonProperties> = ({
  actived,
  children
}) => (
  <Box
    backgroundColor={actived ? 'blue.500' : 'gray.500'}
    cursor="pointer"
    height={12}
    paddingX={4}
    textTransform="uppercase"
    fontWeight="500"
    color={actived ? 'black' : 'white'}
    transition="background 0.3s, color 0.3s"
    display="flex"
    justifyContent="center"
    alignItems="center"
    _focus={{ outline: 'none' }}
    _hover={{ backgroundColor: 'blue.500', color: 'black' }}
  >
    {children}
  </Box>
)

export default NavbarButton

import React, { useState, useContext } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { useToast, Box, Flex, Spinner, Button, Heading } from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import IconButton from '../../components/IconButton'
import { ArrowBackIcon } from '@chakra-ui/icons'

interface CorrectionLetterProperties {
  nfeId: string
  handleSuccess: () => void
  handleCancel: () => void
}

const CorrectionLetter: React.FC<CorrectionLetterProperties> = ({
  nfeId,
  handleSuccess,
  handleCancel
}) => {
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [correction, setCorrection] = useState('')

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()

    setLoading(true)
    const response = await apiContext.put(`/invoices/${nfeId}/letter`, {
      correction
    })
    toast({
      title: response.data.message,
      status: response.status === 200 ? 'success' : 'error',
      isClosable: true,
      duration: 2000
    })
    setLoading(false)

    if (response.status === 200) {
      handleSuccess()
    }
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
    >
      {loading ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Heading size="md" marginTop={-5}>
            Carta de correção - {correction.length}
          </Heading>
          <ReactTextareaAutosize
            style={{
              padding: '12px',
              textAlign: 'justify',
              textTransform: 'uppercase',
              width: '100%',
              outline: 'none',
              border: '1px solid #E2E8F0',
              borderRadius: '10px',
              resize: 'none',
              marginTop: '8px'
            }}
            value={correction}
            onChange={(event) => setCorrection(event.target.value)}
          />
          <Flex marginTop={4} justifyContent="space-between">
            <IconButton
              aria-label="voltar"
              icon={<ArrowBackIcon />}
              onClick={handleCancel}
            />
            <Button textTransform="uppercase" colorScheme="green" type="submit">
              Enviar
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

export default CorrectionLetter

import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../../../../contexts/Company'
import { Box, Flex, Text } from '@chakra-ui/react'
import InputBlock from '../../../../../components/InputBlock'
import Button from '../../../../../components/Button'
import { moneyMask } from '../../../../../helpers/mask'
import { ItemData } from '.'
import { ApiContext } from '../../../../../contexts/Api'

interface NewItemFormProperties {
  formData: ItemData
  setFormData: (data: ItemData) => void
  sketchType: string
  handleSubmit: (itemData: ItemData) => void
}

const NewItemForm: React.FC<NewItemFormProperties> = ({
  formData: form,
  setFormData: setForm,
  sketchType,
  handleSubmit
}) => {
  const apiContext = useContext(ApiContext)
  const companyContext = useContext(CompanyContext)

  const [total, setTotal] = useState('0')

  useEffect(() => {
    let impreciseTotal = 0
    if (typeof form.value === 'number') {
      impreciseTotal = Number(form.value) * Number(form.amount)
    } else {
      impreciseTotal =
        Number(
          form.value.replace('R$ ', '').replace(/\./g, '').replace(',', '.')
        ) * Number(form.amount)
    }
    const preciseTotal = Math.round(impreciseTotal * 100) / 100
    setTotal(moneyMask(preciseTotal))
  }, [form.amount, form.value])

  function internalHandleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()
    handleSubmit(form)
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    let { name, value } = event.target

    if (name === 'description' && value.length > 2000) {
      value = value.substr(0, 2000)
    }

    if (name === 'unity' && value.length > 6) {
      value = value.substr(0, 6)
    }

    setForm({ ...form, [name]: value })
  }

  async function handleCodeBlur() {
    const response = await apiContext.get(`/items/${form.code}?type=code`)
    setForm({ ...form, ...response.data })
  }

  return (
    <Box as="form" onSubmit={internalHandleSubmit}>
      {companyContext.configurations.itemsWithCodes && (
        <InputBlock
          autoFocus={false}
          uppercase
          marginTop
          name="code"
          label="Código"
          value={form.code}
          onChange={handleInputChange}
          onBlur={handleCodeBlur}
        />
      )}

      <InputBlock
        isRequired
        marginTop
        uppercase
        autoFocus
        label={`Descrição ${form.description.length}/2000`}
        name="description"
        value={form.description}
        onChange={handleInputChange}
      />

      {sketchType === 'NFe' && (
        <InputBlock
          isRequired
          marginTop
          label="NCM"
          name="ncm"
          value={form.ncm}
          onChange={handleInputChange}
          mask={[/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/]}
        />
      )}
      <InputBlock
        isRequired
        marginTop
        label="Unidade de medição"
        name="unity"
        value={form.unity}
        onChange={handleInputChange}
      />
      <InputBlock
        isRequired
        marginTop
        type="number"
        label="Quantidade"
        name="amount"
        value={form.amount as string}
        onChange={handleInputChange}
      />
      <InputBlock
        isRequired
        money
        marginTop
        label="Valor unitário"
        name="value"
        value={form.value as string}
        onChange={handleInputChange}
      />
      {companyContext.configurations.orders && (
        <>
          <InputBlock
            marginTop
            label="Número do pedido"
            name="order"
            value={form.order}
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
            onChange={handleInputChange}
          />
          <InputBlock
            marginTop
            label="Número do item do pedido"
            name="orderItemNumber"
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            value={form.orderItemNumber}
            onChange={handleInputChange}
          />
        </>
      )}
      {companyContext.configurations.variableServiceCode && (
        <InputBlock
          isRequired
          marginTop
          label="Código do serviço"
          name="serviceCode"
          value={form.serviceCode}
          onChange={handleInputChange}
          mask={[/\d/, /\d/, '.', /\d/, /\d/]}
        />
      )}
      <Flex marginTop={4} justifyContent="space-between" alignItems="center">
        <Text fontSize="xl" fontWeight="700">
          Total: {total}
        </Text>
        <Button type="submit">Adicionar</Button>
      </Flex>
    </Box>
  )
}

export default NewItemForm

import React, { createContext, useState, useEffect, useContext } from 'react'
import { ApiContext } from './Api'

export interface CompanyConfigurations {
  version: string
  orders: boolean
  variableServiceCode?: boolean
  ISSRetention?: boolean
  itemsWithCodes?: boolean
  decimalLimit: number
}

interface CompanyContextContract {
  configurations: CompanyConfigurations
  updateConfigurations: () => Promise<void | null>
}

export const CompanyContext = createContext<CompanyContextContract>({
  configurations: {
    version: '',
    orders: false,
    decimalLimit: 2
  },
  updateConfigurations: async () => null
})

const CompanyProvider: React.FC = ({ children }) => {
  const apiContext = useContext(ApiContext)
  const [configurations, setConfigurations] = useState<CompanyConfigurations>()

  async function updateConfigurations() {
    const response = await apiContext.get('/companies/type')
    setConfigurations(response.data)
  }

  useEffect(() => {
    apiContext.get('/companies/type').then((response) => {
      setConfigurations(response.data)
    })
  }, [apiContext])

  return (
    <CompanyContext.Provider
      value={{
        configurations: configurations || {
          version: '',
          orders: false,
          decimalLimit: 2
        },
        updateConfigurations
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export default CompanyProvider

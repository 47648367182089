import React, { useState, useEffect, useContext } from 'react'
import { Heading, Flex, Spinner, ListItem, Text } from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import { documentMask } from '../../helpers/mask'
import history from '../../services/history'

import ContainerBox from '../../components/Container'
import List from '../../components/List'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'

interface Consumer {
  id: string
  name: string
  document: string
}

const ConsumersList: React.FC = () => {
  const [consumers, setConsumers] = useState<Consumer[] | undefined>()

  const api = useContext(ApiContext)

  useEffect(() => {
    api.get('/addressees').then((response) => {
      setConsumers(response.data)
    })
  }, [api])

  function openConsumer(id: string) {
    history.push(`/clientes/${id}`)
  }

  return (
    <ContainerBox>
      <Heading fontWeight="300">Clientes</Heading>
      <Flex justifyContent="flex-end">
        <Link to="/clientes/novo">
          <Button>Novo</Button>
        </Link>
      </Flex>
      {consumers === undefined || consumers.length === 0 ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          {consumers === undefined ? (
            <Spinner size="xl" />
          ) : (
            <p>Nenhum cliente foi cadastrado</p>
          )}
        </Flex>
      ) : (
        <List>
          {consumers.map((consumer) => (
            <ListItem
              key={consumer.id}
              padding={3}
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.100' }}
              display="flex"
              justifyContent="space-between"
              onClick={() => openConsumer(consumer.id)}
            >
              <Text
                as="span"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {consumer.name}
              </Text>
              <Text as="span" whiteSpace="nowrap" marginLeft={4}>
                {documentMask(consumer.document)}
              </Text>
            </ListItem>
          ))}
        </List>
      )}
    </ContainerBox>
  )
}

export default ConsumersList

import React, { useState, useContext, ChangeEvent } from 'react'
import {
  Heading,
  Box,
  Select,
  Text,
  Flex,
  useToast,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import history from '../../services/history'
import { cnpjMask, onlyNumbers, serviceCodeMask } from '../../helpers/mask'

import ContainerBox from '../../components/Container'
import Dropzone from 'react-dropzone'
import Button from '../../components/Button'
import InputBlock from '../../components/InputBlock'

interface Form {
  cnpj: string
  stateInscription: string
  municipalInscription: string
  taxRegime: string
  version: string
  defaultServiceCode: string
  certificate?: File
  certificatePassword: string
  numberInvoice: string
  serviceCode: string
  userName: string
  userEmail: string
}

function NovoEmissor() {
  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<Form>({
    cnpj: '',
    stateInscription: '',
    municipalInscription: '',
    taxRegime: '',
    version: '',
    defaultServiceCode: '',
    certificate: undefined,
    certificatePassword: '',
    numberInvoice: '',
    serviceCode: '',
    userName: '',
    userEmail: ''
  })

  const api = useContext(ApiContext)

  async function handleSubmit(event: React.FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)

    const data = new FormData()

    data.append('cnpj', form.cnpj)
    data.append('ie', form.stateInscription)
    data.append('im', form.municipalInscription)
    data.append('regimeTributario', form.taxRegime)
    data.append('versao', form.version)
    if (form.certificate === undefined) {
      toast({
        duration: 2000,
        isClosable: true,
        status: 'error',
        title: 'Adicione o certificado digital da empresa'
      })
      setLoading(false)
      return
    }
    data.append('certificate', form.certificate)
    data.append('password', form.certificatePassword)
    data.append('numberInvoice', form.numberInvoice)
    data.append('serviceCode', form.serviceCode)
    data.append('userName', form.userName)
    data.append('userEmail', form.userEmail)

    const response = await api.post('/companies', data)
    if (response.status >= 400) {
      toast({
        duration: 2000,
        isClosable: true,
        status: 'error',
        title: response.data.message
      })
      setLoading(false)
    }

    toast({
      duration: 2000,
      isClosable: true,
      status: 'success',
      title: 'Emissor cadastrado'
    })
    history.push('/emissores')
  }

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    if (loading) {
      return
    }
    let { name, value } = event.target

    const masks: { [key: string]: () => string } = {
      cnpj: () => cnpjMask(value, form.cnpj),
      stateInscription: () => onlyNumbers(value),
      municipalInscription: () => onlyNumbers(value),
      defaultServiceCode: () => serviceCodeMask(value, form.defaultServiceCode)
    }
    const mask = masks[name]
    if (mask) {
      value = mask()
    }

    setForm({ ...form, [name]: value })
  }

  function handleUpdateCertificate(files: File[]) {
    setForm({ ...form, certificate: files[0] })
  }

  return (
    <ContainerBox>
      <Heading fontWeight="300">Novo emissor</Heading>
      <Box as="form" onSubmit={handleSubmit} marginTop={5}>
        <InputBlock
          isRequired
          name="cnpj"
          value={form.cnpj}
          onChange={handleInputChange}
          label="CNPJ"
        />
        <InputBlock
          isRequired
          marginTop
          name="stateInscription"
          value={form.stateInscription}
          onChange={handleInputChange}
          label="Inscrição estadual"
        />
        <InputBlock
          isRequired
          marginTop
          name="municipalInscription"
          value={form.municipalInscription}
          onChange={handleInputChange}
          label="Inscrição municipal"
        />
        <FormControl isRequired marginTop={2}>
          <FormLabel htmlFor="taxRegime" marginLeft={2} marginBottom={-1}>
            Regime tributário
          </FormLabel>
          <Select
            id="taxRegime"
            name="taxRegime"
            value={form.taxRegime}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>
              Selecione
            </option>
            <option value="1">Simples Nacional</option>
            <option value="2">Simples Nacional (EB)</option>
            <option value="3">Regime normal</option>
          </Select>
        </FormControl>
        <FormControl isRequired marginTop={2}>
          <FormLabel htmlFor="version" marginLeft={2} marginBottom={-1}>
            Tipo de empresa
          </FormLabel>
          <Select
            id="version"
            name="version"
            value={form.version}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>
              Selecione
            </option>
            <option value="sales">Vendas</option>
            <option value="services">Serviços</option>
            <option value="mixed">Vendas e Serviços</option>
          </Select>
        </FormControl>
        {['services', 'mixed'].includes(form.version) && (
          <InputBlock
            isRequired
            marginTop
            name="defaultServiceCode"
            value={form.defaultServiceCode}
            onChange={handleInputChange}
            label="Código de serviço padrão"
          />
        )}

        <Box
          marginTop={3}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          padding={3}
        >
          <Heading as="h3" size="md" fontWeight="300">
            Certificado digital (.pfx)
          </Heading>
          <Dropzone onDropAccepted={handleUpdateCertificate}>
            {({ getRootProps, getInputProps }) => (
              <Box
                marginTop={2}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                paddingY={2}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Text textAlign="center" cursor="pointer">
                  {form.certificate
                    ? form.certificate.name
                    : 'Clique aqui para selecionar o arquivo'}
                </Text>
              </Box>
            )}
          </Dropzone>
          <InputBlock
            isRequired
            marginTop
            type="password"
            name="certificatePassword"
            value={form.certificatePassword}
            onChange={handleInputChange}
            label="Senha"
          />
        </Box>

        <Box
          marginTop={3}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          padding={3}
        >
          <Heading as="h3" size="md" fontWeight="300">
            Usuário
          </Heading>
          <InputBlock
            isRequired
            marginTop
            name="userName"
            value={form.userName}
            onChange={handleInputChange}
            label="Nome e Sobrenome"
          />
          <InputBlock
            isRequired
            marginTop
            type="email"
            name="userEmail"
            value={form.userEmail}
            onChange={handleInputChange}
            label="E-mail"
          />
        </Box>
        <Flex marginTop={3} justifyContent="flex-end">
          <Button isLoading={loading} loadingText="Cadastrando" type="submit">
            Cadastrar
          </Button>
        </Flex>
      </Box>
    </ContainerBox>
  )
}

export default NovoEmissor

import {
  Flex, FormControl,
  FormLabel,
  Select, Spinner,
  useToast
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext } from '../../../contexts/Api'

interface OperationOfTheSketchProperties {
  sketchId: string
  operationId: string
  typeNf: string
  updateOperation: (id: string) => void
}

interface Operation {
  id: string
  description: string
}

const OperationOfTheSketch: React.FC<OperationOfTheSketchProperties> = (
  properties
) => {
  const apiContext = useContext(ApiContext)
  const toast = useToast()

  const [operations, setOperations] = useState<Operation[]>()

  useEffect(() => {
    apiContext.get(`/operations/${properties.typeNf}`).then((response) => {
      setOperations(response.data)
    })
  }, [apiContext, properties.typeNf])

  function handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const operation = event.target.value
    apiContext
      .put(`/sketches/${properties.sketchId}/operation`, {
        operation
      })
      .then((response) => {
        properties.updateOperation(operation)
        toast({
          title: response.data.message,
          status: 'success',
          isClosable: true,
          duration: 2000
        })
      })
  }

  if (!operations) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <FormControl isRequired marginTop={8}>
      <FormLabel htmlFor="operation" marginLeft={2} marginBottom={-3}>
        Operação
      </FormLabel>
      <Select
        id="operation"
        name="operation"
        marginTop={2}
        value={properties.operationId}
        onChange={handleSelectChange}
      >
        {operations.map((operation) => (
          <option key={operation.id} value={operation.id}>
            {operation.description}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default OperationOfTheSketch

import React, { useContext, useState } from 'react'
import { Flex, ListItem, Spinner, Text } from '@chakra-ui/react'
import List from '../../../../../../components/List'
import { ApiContext } from '../../../../../../contexts/Api'
import { AutocompleteItemsContext } from '../..'
import { ItemData } from '..'

interface FindListProperties {
  filter: string
  sketchType: string
  setSelectedItem: (item: ItemData) => void
}

const FindList: React.FC<FindListProperties> = ({
  filter,
  setSelectedItem,
  sketchType
}) => {
  const apiContext = useContext(ApiContext)
  const autocompleteItemsContext = useContext(AutocompleteItemsContext)

  const [loading, setLoading] = useState(false)

  async function handleSelect(item: string) {
    setLoading(true)
    const response = await apiContext.get(
      `/items/${item}?type=description&nfse=${sketchType === 'NFSe'}`
    )
    setLoading(false)
    setSelectedItem(response.data)
  }

  if (!autocompleteItemsContext || loading) {
    return (
      <Flex marginTop={20} justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <List>
      {autocompleteItemsContext
        .filter((item) => item.includes(filter.toUpperCase()))
        .map((item) => (
          <ListItem
            key={item}
            display="flex"
            justifyContent="space-between"
            padding={3}
            cursor="pointer"
            _hover={{ backgroundColor: 'gray.100' }}
            onClick={() => handleSelect(item)}
          >
            <Text
              as="span"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {item}
            </Text>
          </ListItem>
        ))}
    </List>
  )
}

export default FindList

import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Heading,
  ListItem,
  Text,
  useToast,
  Spinner,
  Flex
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import { documentMask } from '../../helpers/mask'

import ContainerBox from '../../components/Container'
import Button from '../../components/Button'
import List from '../../components/List'

interface Company {
  id: string
  name: string
  cnpj: string
}

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[] | undefined>()
  const [userCompany, setUserCompany] = useState<string | undefined>()

  const toast = useToast()
  const api = useContext(ApiContext)

  useEffect(() => {
    api.get('/companies').then((response) => {
      setCompanies(response.data.companies)
      setUserCompany(response.data.userCompany)
    })
  }, [api])

  const selectUserCompany = (id: string) => {
    api.put('/user', { company: id }).then((response) => {
      toast({
        title: response.data.message,
        status: 'info',
        duration: 2000,
        isClosable: true
      })
      setUserCompany(id)
    })
  }

  useEffect(() => {
    api.get('/companies').then((response) => {
      setCompanies(response.data.companies)
    })
  }, [userCompany, api])

  return (
    <ContainerBox>
      <Heading fontWeight="300">Emissores</Heading>
      <Flex justifyContent="flex-end">
        <Link to="/emissores/novo">
          <Button>Novo</Button>
        </Link>
      </Flex>
      {companies === undefined || userCompany === undefined ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <List>
          {companies.map((company) => (
            <ListItem
              key={company.id}
              padding={3}
              cursor="pointer"
              bg={company.id === userCompany ? 'blue.500' : 'white'}
              _hover={{
                backgroundColor:
                  company.id === userCompany ? '#28a6ef' : 'gray.100'
              }}
              display="flex"
              justifyContent="space-between"
              onClick={() => selectUserCompany(company.id)}
            >
              <Text
                as="span"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {company.name}
              </Text>
              <Text as="span" whiteSpace="nowrap" marginLeft={4}>
                {documentMask(company.cnpj)}
              </Text>
            </ListItem>
          ))}
        </List>
      )}
    </ContainerBox>
  )
}

export default Companies

import React from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  ModalFooter
} from '@chakra-ui/react'

interface ModalProperties extends ModalProps {
  header: string
  footer?: React.ReactNode
}

const Modal: React.FC<ModalProperties> = (properties) => (
  <ChakraModal {...properties}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{properties.header}</ModalHeader>
      <ModalBody paddingBottom={4}>{properties.children}</ModalBody>
      <ModalCloseButton _focus={{ outline: 'none' }} />
      {properties.footer && <ModalFooter>{properties.footer}</ModalFooter>}
    </ModalContent>
  </ChakraModal>
)
export default Modal

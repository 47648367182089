import React from 'react'
import styled from '@emotion/styled'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'

interface InputMaskProperties extends MaskedInputProps {
  buttonRight?: boolean
}

const StyledInputMask = styled(MaskedInput)<InputMaskProperties>`
  border: 1px solid #e2e8f0;
  border-radius: ${({ buttonRight }) => (buttonRight ? '8px 0 0 8px' : '8px')};
  height: 40px;
  width: 100%;
  padding: 0 16px;
  font-family: 'Roboto Mono', monospace;

  &:focus {
    border: 1px solid #38b6ff;
    outline: none;
  }
`

const InputMask: React.FC<InputMaskProperties> = (properties) => (
  <StyledInputMask {...properties} guide={false} />
)

export default InputMask

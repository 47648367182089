import React, { useState, useContext, useEffect } from 'react'
import {
  Heading,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ListItem,
  Text
} from '@chakra-ui/react'

import { ApiContext } from '../../contexts/Api'
import history from '../../services/history'

import ContainerBox from '../../components/Container'
import List from '../../components/List'
import Button from '../../components/Button'
import { moneyMask } from '../../helpers/mask'
import { CompanyContext } from '../../contexts/Company'

interface Sketch {
  id: string
  typeNf: string
  value: number
  addressee: string
}

const Invoices: React.FC = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const companyContext = useContext(CompanyContext)
  const apiContext = useContext(ApiContext)

  const [sketches, setSketches] = useState<Sketch[]>()

  useEffect(() => {
    async function requests() {
      await companyContext.updateConfigurations()
      const sketchesResponse = await apiContext.get('/sketches')
      setSketches(sketchesResponse.data)
    }
    requests()
  }, [apiContext])

  function openSketch(id: string) {
    history.push(`/rascunhos/${id}`)
  }

  async function createNF(type: string) {
    const response = await apiContext.post(`/sketches?type=${type}`)
    toast({
      title: response.data.message,
      duration: 2000,
      isClosable: true,
      status: 'success'
    })
    openSketch(response.data.id)
  }

  function handleCreateNewSketch() {
    const types: { [key: string]: () => void } = {
      sales: () => createNF('NFe'),
      services: () => createNF('NFSe'),
      mixed: () => onOpen()
    }
    types[companyContext.configurations.version]()
  }

  return (
    <ContainerBox>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent paddingBottom={5}>
          <ModalHeader>Selecione o tipo de NF</ModalHeader>
          <ModalBody>
            <List>
              <ListItem
                padding={3}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => createNF('NFe')}
              >
                NFe (Comércio)
              </ListItem>
              <ListItem
                padding={3}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => createNF('NFSe')}
              >
                NFSe (Serviço)
              </ListItem>
            </List>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>

      <Heading fontWeight="300">Rascunhos</Heading>
      <Flex justifyContent="flex-end">
        <Button onClick={handleCreateNewSketch}>Novo</Button>
      </Flex>
      {sketches === undefined || sketches.length === 0 ? (
        <Flex height={100} justifyContent="center" alignItems="center">
          {sketches === undefined ? (
            <Spinner size="xl" />
          ) : (
            <p>Nenhum rascunho foi iniciado</p>
          )}
        </Flex>
      ) : (
        <List>
          {sketches.map((sketch) => (
            <ListItem
              key={sketch.id}
              padding={3}
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.100' }}
              display="flex"
              justifyContent="space-between"
              onClick={() => openSketch(sketch.id)}
            >
              <Text
                as="span"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {companyContext.configurations.version === 'mixed' &&
                  `${sketch.typeNf} - `}
                {sketch.addressee || 'Cliente não foi escolhido'}
              </Text>
              <Text as="span" whiteSpace="nowrap" marginLeft={4}>
                {moneyMask(sketch.value)}
              </Text>
            </ListItem>
          ))}
        </List>
      )}
    </ContainerBox>
  )
}

export default Invoices

import React, { useState, useEffect, useContext, createContext } from 'react'

import { ApiContext } from '../../../../contexts/Api'
import { Heading, Flex } from '@chakra-ui/react'
import { moneyMask } from '../../../../helpers/mask'
import NewSketchItem from './New'
import ListOfItems, { SketchItem } from './List'

interface ItemsProperties {
  sketchId: string
  items: SketchItem[]
  typeNf: string
}

export const AutocompleteItemsContext = createContext<string[] | undefined>(
  undefined
)

const ItemsOfTheSketch: React.FC<ItemsProperties> = ({
  sketchId,
  items,
  typeNf
}) => {
  const apiContext = useContext(ApiContext)

  const [autocompleteItems, setAutocompleteItems] = useState<string[]>()
  const [totalValueOfItems, setTotalValueOfItems] = useState(0)

  useEffect(() => {
    async function getItems() {
      const response = await apiContext.get(
        `/items/autocomplete?type=${typeNf}`
      )
      setAutocompleteItems(response.data)
    }
    getItems()
  }, [apiContext, typeNf])

  useEffect(() => {
    let total = 0
    items.forEach((item) => {
      total += parseFloat((item.value as unknown) as string)
    })

    setTotalValueOfItems(Math.round(total * 100) / 100)
  }, [items])

  return (
    <>
      <Heading marginTop={8} size="lg" fontWeight="300">
        Itens
      </Heading>
      <ListOfItems sketchId={sketchId} items={items} />
      <Flex
        justifyContent={totalValueOfItems > 0 ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        {totalValueOfItems > 0 && (
          <Heading size="lg">Total: {moneyMask(totalValueOfItems)}</Heading>
        )}
        <AutocompleteItemsContext.Provider value={autocompleteItems}>
          <NewSketchItem sketchType={typeNf} sketchId={sketchId} />
        </AutocompleteItemsContext.Provider>
      </Flex>
    </>
  )
}

export default ItemsOfTheSketch
